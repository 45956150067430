import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { ISavedQuery } from '../../../@types/SavedQuery';
import { deleteSavedQuery } from '../../../api/queries.api';
import { useTranslation } from 'react-i18next';

interface Props {
    savedQuery: ISavedQuery;
    onClose: () => void;
    onDeleteSuccess: () => void;
    open: boolean;
}

export default function DeleteSavedQueryDialog(props: Props) {
    const { t } = useTranslation();
    const { mutateAsync, isLoading } = useMutation(
        ['deleteSavedQuery'],
        async (queryName: string) => {
            try {
                await deleteSavedQuery(queryName);

                toast.success('SavedQuery deleted successfully');
            } catch {
                toast.error('Error deleting query.');
            }
        }
    );

    const handleDelete = async () => {
        await mutateAsync(props.savedQuery.query_name);
        props.onDeleteSuccess();
    };

    return (
        <Dialog open={props?.open}>
            <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete `{props.savedQuery.query_name}`?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t('core.text.cancel')}</Button>
                <Button onClick={handleDelete} autoFocus color="error" disabled={isLoading}>
                    {t('core.text.delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
