import { Route, Routes, Navigate } from 'react-router-dom';
import ClientPage from './Client';
import LoginPage from './Login';
import InitializePage from './Initialize';
import FronteggRouteGuard from '../guards/FronteggRouteGuard';

const PageRoot = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<Navigate to="/client" />} />
            <Route
                path="/client/*"
                element={
                    <FronteggRouteGuard>
                        <ClientPage />
                    </FronteggRouteGuard>
                }
            />
            <Route
                path="/initialize"
                element={
                    <FronteggRouteGuard>
                        <InitializePage />
                    </FronteggRouteGuard>
                }
            />
        </Routes>
    );
};

export default PageRoot;
