const pt = {
    translation: {
        'core.text.login': 'Conecte-se',
        'core.text.requestInvite': 'Solicitar um convite',
        'core.text.solutions': 'Soluções',
        'core.text.product': 'produtos',
        'core.text.pricePlans': 'Planos de preços',
        'core.text.type': 'tipo de documento',
        'core.text.filename': 'Nome do arquivo',
        'core.text.agreementType': 'Tipo de contrato',
        'core.text.checkbox.exactMatches': 'apenas correspondências exatas',
        'core.text.cancel': 'Cancelar',
        'core.text.delete': 'Excluir',
        'core.text.title': 'Título',
        'core.text.name': 'Nome',
        'core.text.tags': 'Tag',
        'core.text.settings': 'Configurações',
        'core.text.help': 'Ajuda',
        'core.text.logout': 'Sair',
        'core.text.definition': 'Definição',
        'core.text.documents': 'Documentos',
        'core.text.placeholder.searchFilters': 'Procure filtros',
        'feature.text.header':
            'Melior AI facilita a navegação de contratos legais e outros documentos comerciais comuns!',
        'sidebar.text.item.advancedSearch': 'Busca Avançada',
        'sidebar.text.item.savedQueries': 'Consultas salvas',
        'sidebar.text.item.clauseLibrary': 'Biblioteca de Cláusulas',
        'sidebar.text.item.insightTemplates': 'Modelos de insight',
        'sidebar.text.item.approvedClauses': 'Cláusulas aprovadas',
        'sidebar.text.item.comparisons': 'Comparações',
        'sidebar.text.item.workflows': 'Fluxos de trabalho',
        'sidebar.text.item.collaboration': 'Colaboração',
        'documents.table.column.fileName': 'Nome do arquivo',
        'documents.table.column.status': 'Status',
        'documents.action.uploadDocument': 'Documento de upload',
        'documents.action.getInsights': 'Obtenha insights',
        'documents.action.uploading': 'Carregando...',
        'documents.action.finalizing': 'Finalizando...',
        'advancedSearch.table.column.textMatches': 'O texto corresponde',
        'advancedSearch.table.empty': 'Sem resultados',
        'advancedSearch.action.saveQuery': 'Salve a consulta',
        'advancedSearch.action.addFilter': 'Adicione o filtro',
        'advancedSearch.action.clearAll': 'Limpar tudo',
        'advancedSearch.modal.filterList.title': 'Lista de filtros',
        'advancedSearch.modal.filterList.description':
            'Adicione uma ou várias cláusulas para refinar sua consulta',
        'queries.table.title': 'Perguntas',
        'queries.table.column.description': 'Descrição',
        'clauseLibrary.table.title': 'Biblioteca da Cláusula',
        'clauseLibrary.action.addClause': 'Adicionar cláusula',
        'workflows.table.title': 'Fluxos de trabalho',
        'workflows.table.row.action': 'Lançar',
        'workflows.action': 'Editor de fluxo de trabalho',
        'workflows.placeholder.entertag': 'Digite uma tag',
        'comparisons.page.title': 'Comparações',
        'comparisons.page.action.create': 'Criar comparação',
        'comparisons.page.action.viewComparisons': 'Ver comparações',
        'comparisons.modal.title.create': 'Criar comparação',
        'comparisons.modal.title.clauseList': 'Lista de cláusulas',
        'comparisons.modal.title.reviewDetails': 'Comparação: Detalhes da revisão',
        'comparisons.modal.placeholder.comparisonName': 'Nome de comparação',
        'comparisons.modal.field.error.required': 'Este campo é obrigatório',
        'comparisons.modal.placeholder.addDescription': 'Adicionar descrição',
        'comparisons.modal.field.label.yourSavedQueries': 'Suas perguntas salvas',
        'core.text.clauses': 'Cláusulas',
        'core.text.back': 'Voltar',
        'core.text.next': 'Próximo',
        'core.text.update': 'Atualizar',
        'core.text.create': 'Criar',
        'comparisons.page.placeholder.empty': 'Nenhuma comparação encontrada',
        'core.text.error': 'ocorreu um erro',
        'workflows.page.placeholder.loading': 'Carregando fluxos de trabalho',
        'workflows.page.placeholder.empty': 'Nenhum fluxo de trabalho encontrado',
        'clauses.page.placeholder.loading': 'Biblioteca da cláusula de carregamento',
        'comparisons.page.placeholder.loading': 'Carregando comparações',
        'documents.page.placeholder.loading': 'Carregando documentos',
        'documents.page.placeholder.empty': 'Nenhum documento encontrado',
        'documents.page.delete.confirm.singular': 'Tem certeza de que deseja excluir o seguinte documento?',
        'documents.page.delete.confirm.plural': 'Tem certeza de que deseja excluir os seguintes documentos?',
        'documents.page.delete.confirm.message': 'Essa ação não pode ser desfeita.',
        'documents.page.delete.loading.plural': 'Excluindo documentos...',
        'documents.page.delete.loading.singular': 'Excluindo documento...',
        'documents.page.alert.error.plural': 'Erro ao excluir documentos.',
        'documents.page.alert.success.plural': 'Documentos excluídos com sucesso.',
        'documents.page.alert.error.singular': 'Erro ao excluir documento.',
        'documents.page.alert.success.singular': 'Documento excluído com sucesso.',
        'queries.page.placeholder.loading': 'Carregando consultas',
        'queries.page.placeholder.empty':
            'Você não tem perguntas salvas, mas pode pesquisar e salvar seu\n' +
            '                                        primeiro do',
        'core.text.page': 'página',
        'getinsights.action.editSelection': 'Editar seleção',
        'core.text.select': 'Selecione',
        'getInsights.action.showClauses': 'Mostrar cláusulas',
        'getInsights.action.showKeyData': 'Mostrar dados -chave',
        'getInsights.title.insightList': 'Lista de insights',
        'getInsights.placeholder.empty.entities': 'Não há entidades para este documento',
        'core.text.insights': 'Percepções',
        'core.text.keyData': 'Data chave',
        'core.text.chat': 'Bater papo',
        'core.text.capital.page': 'Página',
        'core.text.of': 'de',
        'core.text.matches': 'Partidas',
        'core.text.reset': 'Reiniciar',
        'core.text.confirm': 'Confirme',
        'core.text.placeholder.loadingConfig': 'Carregando configuração',
    },
};

export default pt;
