import Box from '@mui/material/Box';
import {
    DataGridPremium,
    GridColDef,
    GridColumnHeaders,
    GridRow,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { memo, useCallback, useEffect, useState } from 'react';
import { IDocument } from '../../../@types/Document';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Container from '../../UI/Common/Container';
import { Text } from '../../UI/Typography';
import { Theme } from '../../../theme';
import { Button, Chip } from '@mui/material';
import SearchResultsCount from './SearchResultsCount';
import { isEmpty } from 'lodash';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ISearchResultsTableProps {
    documents: IDocument[];
    isLoading: boolean;
}

const SearchResultsTable = ({ documents, isLoading }: ISearchResultsTableProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const mainColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('core.text.filename'),
            width: 300,
            renderCell: (params) => (
                <Container
                    cursor="pointer"
                    wordBreak
                    onClick={() => navigate(`/client/insights/${params.id}`)}
                >
                    <Text customFontWeight={500} color={Theme.primary}>
                        <div dangerouslySetInnerHTML={{ __html: params.value }} />
                    </Text>
                </Container>
            ),
        },
        {
            field: 'type',
            headerName: t('core.text.agreementType'),
            width: 200,
            renderCell: (params) => (
                <Chip
                    label={<p dangerouslySetInnerHTML={{ __html: params.value }} />}
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        color: 'rgba(13, 24, 50, 0.87)',
                    }}
                />
            ),
        },
        {
            field: '_highlights',
            headerName: t('advancedSearch.table.column.textMatches'),
            width: 250,
            valueGetter: (params) => {
                return params.value?.text;
            },
            sortable: false,
            renderCell: (params) => (
                <Carousel
                    statusFormatter={(currentItem, total) => `${currentItem} of ${total} Matches`}
                    showIndicators={false}
                    width="100%"
                    showArrows={true}
                >
                    {params.value &&
                        params.value.length &&
                        params.value.map((textMatch) => (
                            <Container wordBreak dangerouslySetInnerHTML={{ __html: textMatch }} />
                        ))}
                </Carousel>
            ),
        },
    ];
    const MemoizedRow = memo(GridRow);
    const MemoizedColumnHeaders = memo(GridColumnHeaders);
    const [columns, setColumns] = useState<GridColDef[]>(mainColumns);

    const apiRef = useGridApiRef();
    const table = useFullScreenHandle();
    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        if (documents.length) {
            const copyColumns = [...mainColumns];

            const clauses = Object.keys(documents[0].clauses);
            const hasHighlightsText = documents.find(
                (document) => Object.keys(document['_highlights']).length
            );
            if (!clauses.length && !hasHighlightsText) {
                copyColumns.splice(-1);
                setColumns(copyColumns);
                return;
            }

            if (!hasHighlightsText) {
                copyColumns.splice(-1);
            }

            let clausesColumnArr: GridColDef[] = [];
            clauses.map((clauseKey) => {
                clausesColumnArr.push({
                    field: clauseKey,
                    headerName: clauseKey,
                    width: 250,
                    renderCell: (params) => {
                        if (!(params.row && params.row.clauses && params.row.clauses[clauseKey])) {
                            return <></>;
                        }

                        if (Array.isArray(params.row.clauses[clauseKey])) {
                            return (
                                <Carousel
                                    statusFormatter={(currentItem, total) =>
                                        `${currentItem} of ${total} Matches`
                                    }
                                    showIndicators={false}
                                    width="100%"
                                    showArrows={true}
                                >
                                    {params?.row?.clauses[clauseKey]?.map((clauseExtraction) => (
                                        <Container
                                            wordBreak
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    clauseExtraction.highlights ||
                                                    clauseExtraction.text ||
                                                    clauseExtraction.value,
                                            }}
                                        />
                                    ))}
                                </Carousel>
                            );
                        }

                        const text =
                            params.row.clauses[clauseKey].highlights ||
                            params.row.clauses[clauseKey].text ||
                            params.row.clauses[clauseKey].value;
                        return <Container wordBreak dangerouslySetInnerHTML={{ __html: text }} />;
                    },
                    valueGetter: (params) =>
                        params.row.clauses[clauseKey]?.highlights ||
                        params.row.clauses[clauseKey]?.text ||
                        params.row.clauses[clauseKey]?.value,
                    sortComparator: (a, b) => {
                        if (!a) return 1;
                        if (!b) return -1;
                        if (!a && !b) return 0;
                        return a[0].localeCompare(b[0]);
                    },
                });
            });

            setColumns(copyColumns.concat(clausesColumnArr));
        } else {
            setColumns(mainColumns);
        }
    }, [documents]);

    const reportChange = useCallback(
        (state, handle) => {
            if (handle === table) {
                setIsFullscreen(state);
            }
        },
        [table]
    );

    function CustomToolbar() {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <GridToolbarContainer>
                    <GridToolbarColumnsButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport style={{ color: 'rgb(0, 177, 120)' }} />
                </GridToolbarContainer>
                <Button onClick={isFullscreen ? table.exit : table.enter}>
                    {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                    <Text color="black" style={{ marginLeft: '5px' }}>
                        {isFullscreen ? 'Exit' : 'Enter'} fullscreen
                    </Text>
                </Button>
            </Box>
        );
    }
    return (
        <FullScreen handle={table} onChange={reportChange}>
            <Box
                sx={{
                    width: '100%',
                    background: isFullscreen ? 'white' : '',
                    height: isFullscreen ? '100vh' : 520,
                    paddingTop: isFullscreen ? '10px' : '0px',
                    overflow: 'scroll',
                }}
            >
                {!isEmpty(documents) && <SearchResultsCount count={documents.length} />}

                <DataGridPremium
                    slotProps={{
                        basePopper: {
                            disablePortal: true,
                            placement: 'auto',
                        },
                    }}
                    disableColumnResize={true}
                    hideFooter={true}
                    getRowHeight={() => 'auto'}
                    rows={documents}
                    columns={columns}
                    apiRef={apiRef}
                    loading={isLoading}
                    disableRowSelectionOnClick
                    slots={{ toolbar: CustomToolbar }}
                    localeText={{
                        noRowsLabel: t('advancedSearch.table.empty'),
                    }}
                    components={{
                        Row: MemoizedRow,
                        ColumnHeaders: MemoizedColumnHeaders,
                    }}
                />
            </Box>
        </FullScreen>
    );
};

export default SearchResultsTable;
