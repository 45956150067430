import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { IWorkflow } from '../../../@types/Workflow';
import { deleteWorkflow } from '../../../api/n8n/workflows';
import { useTranslation } from 'react-i18next';

interface Props {
    workflow: IWorkflow;
    onClose: () => void;
    onDeleteSuccess: () => void;
    open: boolean;
}

export default function DeleteWorkflowModal({ workflow, onClose, onDeleteSuccess, open }: Props) {
    const { t } = useTranslation();
    const { mutateAsync, isLoading } = useMutation(
        ['deleteWorkflow'],
        async (documentId: string) => {
            try {
                //delete workflow here

                await deleteWorkflow(documentId);

                toast.success('Workflow deleted successfully');
            } catch {
                toast.error('Error deleting workflow.');
            }
        }
    );

    const handleDelete = async () => {
        await mutateAsync(workflow.id);
        onDeleteSuccess();
    };

    return (
        <Dialog open={open}>
            <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete `{workflow.name}`?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('core.text.cancel')}</Button>
                <Button onClick={handleDelete} autoFocus color="error" disabled={isLoading}>
                    {t('core.text.delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
