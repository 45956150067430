import Flex from 'styled-flex-component';
import { Text } from '../../UI/Typography';
import Container from '../../UI/Common/Container';
import { isEmpty } from 'lodash';
import { Theme } from '../../../theme';
import Empty from '../../UI/Common/Empty';
import {
    ExpandLess,
    KeyboardArrowRight,
    PlaylistAddCheck,
    SelectAllOutlined,
    VisibilityOff,
} from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { IFeedback } from '../../../@types/Feedback';
import { provideFeedback } from '../../../api/feedback';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../pages/Client/GetInsights/context/getInsightsContext';
import { useSearchParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { getTemplate, storeTemplate } from '../../../utils/userInsightsTemplate';
import { IDisplayClause } from '../../../@types/Comparison';
import { toast } from 'react-toastify';
import ClausesListItem from './ClausesListItem';
import TextMatchesNavigation from './TextMatchesNavigation';
import { useTranslation } from 'react-i18next';

interface ICLausesListProps {
    getDocument: () => void;
}

const ClausesList = ({ getDocument }: ICLausesListProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const clauseSearchParam = searchParams.get('clause');
    const {
        documentData,
        selectedInsight,
        editSelectedClause,
        selectedRegion,
        textMatchIndex,
        setSelectedRegion,
        setEditSelectedClause,
        setSelectedInsight,
        setTextMatchIndex,
    } = useContext(GetInsightsContext) as GetInsightsContextType;
    const clauses = documentData.clauses ? Object.keys(documentData.clauses) : [];
    const [hoveredInsight, setHoveredInsight] = useState<string>();
    const [draggedInsight, setDraggedInsight] = useState<string>();
    const [initialClauses, setInitialClauses] = useState<Array<IDisplayClause>>([]);
    const [displayedClauses, setDisplayedClauses] = useState<Array<IDisplayClause>>([]);
    const [hiddenClauses, setHiddenClauses] = useState<Array<IDisplayClause>>([]);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [isResetting, setIsResetting] = useState<boolean>(false);
    const [isDisplayingBack, setIsDisplayingBack] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const isEditing = (key) => Boolean(editSelectedClause && editSelectedClause.clause !== key);

    /**use effect for checking if clause query param exists, select that clause as insight */
    useEffect(() => {
        // Get saved template stored in session
        const sessionDocumentTemplate = getTemplate(documentData.name);
        // Get saved template stored in session
        const sessionDisplayedInsights: Array<IDisplayClause> = sessionDocumentTemplate.list;

        // Merge saved template with document clauses
        if (!sessionDisplayedInsights?.length) {
            // Attach ID to each clause
            let clausesWithId = attachClauseId(clauses, 0);
            // Set displayed clauses
            setDisplayedClauses(clausesWithId);
            setInitialClauses(clausesWithId.concat([]));
        } else {
            // Get all clauses in session
            const sessionDisplayed = sessionDisplayedInsights.map((insight) => insight.key);
            // Merge clauses in saved template with document clauses
            const sessionClausesInDocument = sessionDisplayed.filter((insight) =>
                clauses.includes(insight)
            );
            // Attach ID to each clause
            let clausesWithId = attachClauseId(sessionClausesInDocument, 0);
            // Set displayed clauses
            setDisplayedClauses(clausesWithId);
            setInitialClauses(clausesWithId.concat([]));

            // Set the rest of the clauses as hidden
            const documentClausesNotInSession = clauses.filter(
                (insight) => !sessionDisplayed.includes(insight)
            );
            // Attach ID to each clause
            let hiddenClausesWithId = attachClauseId(
                documentClausesNotInSession,
                sessionClausesInDocument.length
            );
            // Set hidden clauses
            setHiddenClauses(hiddenClausesWithId);
        }

        if (!clauseSearchParam) return;
        if (!clauses.includes(clauseSearchParam)) return;

        setSelectedInsight(clauseSearchParam);
    }, []);

    useEffect(() => {
        if (isExpanded || isDisplayingBack) {
            scrollToDisplayBottom();
        }
        if (isDisplayingBack) setIsDisplayingBack(false);
    }, [isExpanded, displayedClauses, isDisplayingBack]);

    useEffect(() => {
        setHasChanges(JSON.stringify(displayedClauses) != JSON.stringify(initialClauses));
    }, [draggedInsight, displayedClauses.length]);

    const attachClauseId = (clauses, startIndex) => {
        let clausesWithId: Array<IDisplayClause> = [];
        clauses.forEach((item, i) => {
            clausesWithId.push({
                id: (startIndex + i + 1).toString(),
                key: item,
            });
            return;
        });
        return clausesWithId;
    };

    async function reset() {
        setIsResetting(true);

        const feedback: IFeedback[] = [
            {
                correct: false,
                gt: documentData.clauses[editSelectedClause!.clause][textMatchIndex]._predicted
                    ?.answer as string,
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause?.clause}`,
                key: 'answer',
            },
            {
                correct: false,
                gt: documentData.clauses[editSelectedClause!.clause][textMatchIndex]._predicted
                    ?.bboxes as number[][],
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause!.clause}`,
                key: 'bboxes',
            },
        ];
        try {
            await provideFeedback(documentData.id, feedback);
            setEditSelectedClause(undefined);
            setSelectedRegion(undefined);
            setIsResetting(false);
            getDocument();
        } catch (e) {
            console.error(e);
            setIsResetting(false);
        }
    }

    const getBboxesValue = (): number[][] => {
        let arr: number[][] = [];
        if (!selectedRegion?.bboxes.length) {
            return [];
        }

        selectedRegion.bboxes.map((bbox) => {
            arr.push([bbox.left, bbox.top, bbox.width, bbox.height]);
        });

        return arr as number[][];
    };

    async function confirm() {
        if (!selectedRegion || !editSelectedClause) return;

        setIsConfirming(true);
        const feedback: IFeedback[] = [
            {
                correct: false,
                gt: selectedRegion.text,
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause.clause}`,
                key: 'answer',
            },
            {
                correct: false,
                gt: getBboxesValue(),
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause.clause}`,
                key: 'bboxes',
            },
            {
                correct: false,
                gt: selectedRegion.page,
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause.clause}`,
                key: 'page',
            },
        ];

        try {
            await provideFeedback(documentData.id, feedback);
            setEditSelectedClause(undefined);
            setIsConfirming(false);
            setSelectedRegion(undefined);
            getDocument();
        } catch (e) {
            console.error(e);
            setIsConfirming(false);
        }
    }

    const dragEnd = (result) => {
        setDraggedInsight('');
        const { destination, source, draggableId } = result;
        // Released outside of droppable area
        if (!destination) return;
        // Order not changed
        if (source.index == destination.index && source.droppableId == destination.droppableId)
            return;

        if (source.droppableId != destination.droppableId) {
            //remove from source
            let sourceList = source.droppableId == 'displayed' ? displayedClauses : hiddenClauses;
            let clause = sourceList.find((clause) => clause.id == draggableId);
            sourceList.splice(source.index, 1);
            //insert to destination
            let destinationList =
                destination.droppableId == 'displayed' ? displayedClauses : hiddenClauses;
            destinationList.splice(destination.index, 0, clause as IDisplayClause);
            // update lists
            const displayed = source.droppableId == 'displayed' ? sourceList : destinationList;
            const hidden = source.droppableId == 'hidden' ? sourceList : destinationList;
            setDisplayedClauses(displayed);
            setHiddenClauses(hidden);

            if (!hidden.length) setIsExpanded(false);

            return;
        }
        const listToUpdate = source.droppableId == 'displayed' ? displayedClauses : hiddenClauses;
        let clause = listToUpdate.find((clause) => clause.id == draggableId);
        listToUpdate.splice(source.index, 1);
        listToUpdate.splice(destination.index, 0, clause as IDisplayClause);
        source.droppableId == 'displayed'
            ? setDisplayedClauses(listToUpdate)
            : setHiddenClauses(listToUpdate);
    };

    const dragStart = (result) => {
        setDraggedInsight(result.draggableId);
    };

    const removeInsight = (e, clause, index) => {
        e.stopPropagation();

        if (hiddenClauses.includes(clause)) return;

        displayedClauses.splice(index, 1);
        setDisplayedClauses(displayedClauses);

        hiddenClauses.unshift(clause);
        setHiddenClauses(hiddenClauses);
        setHoveredInsight('');
    };

    const displayInsight = (clause, index) => {
        setIsDisplayingBack(true);
        if (displayedClauses.includes(clause)) return;

        hiddenClauses.splice(index, 1);
        setHiddenClauses(hiddenClauses);
        if (!hiddenClauses.length) setIsExpanded(false);

        displayedClauses.push(clause);
        setDisplayedClauses(displayedClauses);
        setHoveredInsight('');
    };

    const scrollToDisplayBottom = () => {
        const element: any = document.getElementById('displayed-clauses-bottom');
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    const handleSave = () => {
        try {
            storeTemplate({
                documentName: documentData.name,
                list: displayedClauses,
            });
            setHasChanges(false);
            setInitialClauses(displayedClauses.concat([]));
            toast.success('Template saved successfully.');
        } catch (e) {
            toast.error('Unable to save template.');
        }
    };

    const isClauseParsed = (key: string) => {
        return (
            (key.includes('Date') && !isEmpty(documentData.clauses[key][0]?.value)) ||
            !key.includes('Date')
        );
    };

    const editSelection = (e, clause) => {
        e.stopPropagation();
        setEditSelectedClause({
            clause: clause.key,
            answer: documentData.clauses[clause.key][textMatchIndex].answer,
        });
        setSelectedRegion(undefined);
    };

    return (
        <div>
            <Box
                sx={{
                    borderBottom: 1,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingLeft: 2,
                    paddingRight: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Flex>
                    <Text customFontSize={0.9}>{t('getInsights.title.insightList')}</Text>
                    <Text
                        style={{
                            background: 'lightgray',
                            borderRadius: '50%',
                            marginLeft: '10px',
                            height: '20px',
                            width: '20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        {displayedClauses.length}
                    </Text>
                </Flex>
                <div>
                    {hasChanges && <Button onClick={handleSave}>Save</Button>}
                    <IconButton>
                        <PlaylistAddCheck />
                    </IconButton>
                </div>
            </Box>
            <DragDropContext onDragEnd={dragEnd} onDragStart={dragStart}>
                <Container id="clauses-list">
                    {!Boolean(clauses.length) && (
                        <Container outerSpacing={2}>
                            <Empty message="There are no clauses for this document" />
                        </Container>
                    )}
                    <Droppable droppableId="displayed">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{
                                    maxHeight: isExpanded
                                        ? 'calc(50vh - 163px)'
                                        : hiddenClauses.length
                                        ? 'calc(100vh - 254px)'
                                        : 'calc(100vh - 178px)',
                                    overflow: 'scroll',
                                }}
                                id="displayed-clauses"
                            >
                                {Boolean(clauses.length) &&
                                    displayedClauses.map((clause, index) => (
                                        <Container key={clause.id}>
                                            {selectedInsight !== clause.key && (
                                                <Draggable draggableId={clause.id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <ClausesListItem
                                                                index={index}
                                                                clause={clause}
                                                                hoveredInsight={
                                                                    hoveredInsight as string
                                                                }
                                                                draggedInsight={
                                                                    draggedInsight as string
                                                                }
                                                                setHoveredInsight={
                                                                    setHoveredInsight
                                                                }
                                                                isEditing={isEditing}
                                                                removeInsight={removeInsight}
                                                                displayInsight={displayInsight}
                                                                setSelectedInsight={
                                                                    setSelectedInsight
                                                                }
                                                                setTextMatchIndex={
                                                                    setTextMatchIndex
                                                                }
                                                                isHideable={true}
                                                                isParsed={isClauseParsed(
                                                                    clause.key
                                                                )}
                                                                editSelection={editSelection}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )}
                                            {selectedInsight === clause.key && (
                                                <Container
                                                    borderLeft={`4px solid ${Theme.primary}`}
                                                    borderBottom="1px solid rgba(13, 24, 50, 0.12)"
                                                    leftInnerSpacing={2}
                                                    topInnerSpacing={2}
                                                    bottomInnerSpacing={1.5}
                                                    rightInnerSpacing={1.625}
                                                    onClick={() => {
                                                        setTextMatchIndex(0);
                                                        setSelectedInsight('');
                                                    }}
                                                >
                                                    <Flex alignCenter justifyBetween>
                                                        <Text
                                                            customFontWeight={600}
                                                            color={Theme.primaryDark}
                                                        >
                                                            {clause.key}
                                                        </Text>
                                                        <IconButton
                                                            onClick={(e) => {
                                                                removeInsight(e, clause, index);
                                                            }}
                                                        >
                                                            <VisibilityOff
                                                                sx={{
                                                                    color: 'rgba(13, 24, 50, 0.54)',
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Flex>

                                                    <Container
                                                        topOuterSpacing={1.375}
                                                        backgroundColor={Theme.background}
                                                        leftInnerSpacing={0.75}
                                                        topInnerSpacing={1}
                                                        bottomInnerSpacing={1}
                                                        rightInnerSpacing={0.75}
                                                        minHeight={6.09375}
                                                        wordBreak
                                                    >
                                                        {isEmpty(
                                                            documentData.clauses[clause.key]
                                                        ) && <i style={{ opacity: 0.4 }}>empty</i>}

                                                        {!isEmpty(
                                                            documentData.clauses[clause.key]
                                                        ) && (
                                                            <Flex
                                                                column
                                                                justifyBetween
                                                                style={{ minHeight: '6.09375rem' }}
                                                            >
                                                                {documentData.clauses[clause.key]
                                                                    .length && (
                                                                    <Text
                                                                        customFontSize={0.8125}
                                                                        customFontWeight={400}
                                                                    >
                                                                        {selectedRegion
                                                                            ? selectedRegion.text
                                                                            : documentData.clauses[
                                                                                  clause.key
                                                                              ][
                                                                                  documentData
                                                                                      .clauses[
                                                                                      clause.key
                                                                                  ].length > 1
                                                                                      ? textMatchIndex
                                                                                      : 0
                                                                              ].answer}
                                                                    </Text>
                                                                )}

                                                                {documentData.clauses[clause.key]
                                                                    .length > 1 && (
                                                                    <TextMatchesNavigation
                                                                        items={
                                                                            documentData.clauses[
                                                                                clause.key
                                                                            ]
                                                                        }
                                                                    />
                                                                )}
                                                            </Flex>
                                                        )}
                                                    </Container>

                                                    {!Boolean(editSelectedClause) && (
                                                        <Container
                                                            topOuterSpacing={1}
                                                            textAlign="right"
                                                        >
                                                            <Flex full justifyEnd>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="success"
                                                                    onClick={(e) =>
                                                                        editSelection(e, clause)
                                                                    }
                                                                >
                                                                    <Flex alignCenter>
                                                                        <SelectAllOutlined />
                                                                        <Container
                                                                            leftOuterSpacing={
                                                                                0.6875
                                                                            }
                                                                        >
                                                                            {t(
                                                                                'getinsights.action.editSelection'
                                                                            )}
                                                                        </Container>
                                                                    </Flex>
                                                                </Button>
                                                            </Flex>
                                                        </Container>
                                                    )}
                                                    {Boolean(editSelectedClause) && (
                                                        <Container
                                                            topOuterSpacing={1}
                                                            textAlign="right"
                                                        >
                                                            <Flex full justifyEnd>
                                                                <Button
                                                                    color="primary"
                                                                    sx={{ marginRight: 1 }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        reset();
                                                                    }}
                                                                    disabled={isResetting}
                                                                >
                                                                    {t('core.text.reset')}
                                                                </Button>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="success"
                                                                    disabled={
                                                                        isConfirming ||
                                                                        !selectedRegion
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        confirm();
                                                                    }}
                                                                >
                                                                    {t('core.text.confirm')}
                                                                </Button>
                                                            </Flex>
                                                        </Container>
                                                    )}
                                                </Container>
                                            )}
                                        </Container>
                                    ))}
                                {provided.placeholder}
                                <div id="displayed-clauses-bottom" />
                            </div>
                        )}
                    </Droppable>
                    <Accordion
                        elevation={5}
                        sx={{
                            bottom: 0,
                            width: '100%',
                            maxHeight: '50vh',
                            display: hiddenClauses.length ? 'block' : 'none',
                        }}
                        expanded={isExpanded}
                        onChange={(e, expanded) => {
                            setIsExpanded(expanded);
                        }}
                    >
                        <AccordionSummary
                            expandIcon={isExpanded ? <ExpandLess /> : <KeyboardArrowRight />}
                            style={{
                                height: '75px',
                                paddingLeft: '40px',
                                borderBottom: hiddenClauses.length
                                    ? '1px solid rgba(13, 24, 50, 0.12)'
                                    : '',
                            }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Text color={Theme.primaryDark}>Other Insights</Text>
                            <Text
                                style={{
                                    background: 'lightgray',
                                    borderRadius: '50%',
                                    marginLeft: '10px',
                                    height: '20px',
                                    width: '20px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                }}
                            >
                                {hiddenClauses.length}
                            </Text>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                overflow: 'scroll',
                                height: '40vh',
                                padding: 0,
                            }}
                        >
                            <Droppable droppableId="hidden">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {Boolean(hiddenClauses.length) &&
                                            hiddenClauses.map((clause, index) => (
                                                <Container key={clause.id}>
                                                    <Draggable
                                                        draggableId={clause.id}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <ClausesListItem
                                                                    index={index}
                                                                    clause={clause}
                                                                    hoveredInsight={
                                                                        hoveredInsight as string
                                                                    }
                                                                    draggedInsight={
                                                                        draggedInsight as string
                                                                    }
                                                                    setHoveredInsight={
                                                                        setHoveredInsight
                                                                    }
                                                                    removeInsight={removeInsight}
                                                                    displayInsight={displayInsight}
                                                                    setSelectedInsight={
                                                                        setSelectedInsight
                                                                    }
                                                                    isHideable={false}
                                                                    isEditing={isEditing}
                                                                    isParsed={isClauseParsed(
                                                                        clause.key
                                                                    )}
                                                                    editSelection={editSelection}
                                                                    setTextMatchIndex={
                                                                        setTextMatchIndex
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </Container>
                                            ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </AccordionDetails>
                    </Accordion>
                </Container>
            </DragDropContext>
        </div>
    );
};

export default ClausesList;
