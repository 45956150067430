import { getUserSessionData } from '../../utils/userSession';
import middlewareAPIClient from '../middleware/apiClient';

const user = getUserSessionData();

export const getWorkflows = async (params) => {
    return middlewareAPIClient
        .get(`/n8n/workflows?active=true`, {
            params,
            headers: {
                Authorization: `Bearer ${user?.accessToken}`,
            },
        })
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};

export const deleteWorkflow = async (workflowId: string) => {
    return middlewareAPIClient
        .delete(`/n8n/workflows/${workflowId}`, {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`,
            },
        })
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};
