import { IClause } from '../@types/SavedQuery';
import apiClient from './apiClient';

export const fetchClauseLibrary = async () => {
    return apiClient
        .get(`/clause/library`)
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};

export const updateClause = async (clauses: IClause[]) => {
    return apiClient
        .put(`/clause/library`, clauses)
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};