import { Theme } from '../../../theme';

interface Props {
    count: number;
}

export default function SearchResultsCount(props: Props) {
    return (
        <div
            style={{
                textAlign: 'right',
                margin: '10px 20px',
                fontSize: '0.8rem',
                color: Theme.primaryDark,
            }}
        >
            Total Results: {props.count}
        </div>
    );
}
