const es = {
    translation: {
        'core.text.login': 'Acceso',
        'core.text.requestInvite': 'Solicitar una invitación',
        'core.text.solutions': 'Soluciones',
        'core.text.product': 'Producto',
        'core.text.pricePlans': 'Planes de precio',
        'core.text.type': 'Tipo de Documento',
        'core.text.filename': 'Nombre del archivo',
        'core.text.agreementType': 'Tipo de acuerdo',
        'core.text.checkbox.exactMatches': 'solo partidos exactos',
        'core.text.cancel': 'Cancelar',
        'core.text.delete': 'Borrar',
        'core.text.title': 'Título',
        'core.text.name': 'Nombre',
        'core.text.tags': 'Etiquetas',
        'core.text.settings': 'Ajustes',
        'core.text.help': 'Ayuda',
        'core.text.logout': 'Cerrar sesión',
        'core.text.definition': 'Definición',
        'core.text.documents': 'Documentos',
        'core.text.placeholder.searchFilters': 'Buscar filtros',
        'feature.text.header':
            '¡Melior AI hace que navegar en pilas de contratos legales y otros documentos comerciales comunes sea muy fácil!',
        'sidebar.text.item.advancedSearch': 'Búsqueda Avanzada',
        'sidebar.text.item.savedQueries': 'Consultas guardadas',
        'sidebar.text.item.clauseLibrary': 'Biblioteca de cláusulas',
        'sidebar.text.item.insightTemplates': 'Plantillas de información',
        'sidebar.text.item.approvedClauses': 'Cláusulas aprobadas',
        'sidebar.text.item.comparisons': 'Comparaciones',
        'sidebar.text.item.workflows': 'Flujos de trabajo',
        'sidebar.text.item.collaboration': 'Colaboración',
        'documents.table.column.fileName': 'Nombre del archivo',
        'documents.table.column.status': 'Estado',
        'documents.action.uploadDocument': 'Cargar documento',
        'documents.action.getInsights': 'Obtener información',
        'documents.action.uploading': 'Cargando...',
        'documents.action.finalizing': 'Finalizando...',
        'advancedSearch.table.column.textMatches': 'Coincidencias de texto',
        'advancedSearch.table.empty': 'No hay resultados',
        'advancedSearch.action.saveQuery': 'Guardar consulta',
        'advancedSearch.action.addFilter': 'Añadir filtro',
        'advancedSearch.action.clearAll': 'Limpiar todo',
        'advancedSearch.modal.filterList.title': 'Lista de filtros',
        'advancedSearch.modal.filterList.description':
            'Agregue una o múltiples cláusulas para refinar su consulta',
        'queries.table.title': 'Consultas',
        'queries.table.column.description': 'Descripción',
        'clauseLibrary.table.title': 'Biblioteca de cláusula',
        'clauseLibrary.action.addClause': 'Agregar cláusula',
        'workflows.table.title': 'Flujos de trabajo',
        'workflows.table.row.action': 'Lanzamiento',
        'workflows.action': 'Editor de flujo de trabajo',
        'workflows.placeholder.entertag': 'Ingrese una etiqueta',
        'comparisons.page.title': 'Comparaciones',
        'comparisons.page.action.create': 'Crear comparación',
        'comparisons.page.action.viewComparisons': 'Ver comparaciones',
        'comparisons.modal.title.create': 'Crear comparación',
        'comparisons.modal.title.clauseList': 'Lista de cláusulas',
        'comparisons.modal.title.reviewDetails': 'Comparación: detalles de revisión',
        'comparisons.modal.placeholder.comparisonName': 'Nombre de comparación',
        'comparisons.modal.field.error.required': 'Este campo es obligatorio',
        'comparisons.modal.placeholder.addDescription': 'Agregar descripción',
        'comparisons.modal.field.label.yourSavedQueries': 'Tus consultas guardadas',
        'core.text.clauses': 'Cláusulas',
        'core.text.back': 'Atrás',
        'core.text.next': 'Próximo',
        'core.text.update': 'Actualizar',
        'core.text.create': 'Crear',
        'comparisons.page.placeholder.empty': 'No se encontraron comparaciones',
        'core.text.error': 'Se ha producido un error',
        'workflows.page.placeholder.loading': 'Carga de flujos de trabajo',
        'workflows.page.placeholder.empty': 'No se encontraron flujos de trabajo',
        'clauses.page.placeholder.loading': 'Biblioteca de cláusula de carga',
        'comparisons.page.placeholder.loading': 'Cargas de comparaciones',
        'documents.page.placeholder.loading': 'Cargando documentos',
        'documents.page.placeholder.empty': 'No se encontraron documentos',
        'documents.page.delete.confirm.singular': 'Está seguro de que desea eliminar el siguiente documento?',
        'documents.page.delete.confirm.plural': 'Está seguro de que desea eliminar los siguientes documentos?',
        'documents.page.delete.confirm.message': 'Esta acción no se puede deshacer.',
        'documents.page.delete.loading.plural': 'Supresión de documentos...',
        'documents.page.delete.loading.singular': 'Eliminando documento...',
        'documents.page.alert.error.plural': 'Error al eliminar documentos.',
        'documents.page.alert.success.plural': 'Documentos eliminados exitosamente.',
        'documents.page.alert.error.singular': 'Error al eliminar el documento.',
        'documents.page.alert.success.singular': 'Documento eliminado exitosamente.',
        'queries.page.placeholder.loading': 'Cargando consultas',
        'queries.page.placeholder.empty':
            'No tiene consultas guardadas, pero puede buscar y guardar su\n' +
            '                                        primero de la',
        'core.text.page': 'página',
        'getinsights.action.editSelection': 'Editar selección',
        'core.text.select': 'Seleccionar',
        'getInsights.action.showClauses': 'Mostrar cláusulas',
        'getInsights.action.showKeyData': 'Mostrar datos clave',
        'getInsights.title.insightList': 'Lista de información',
        'getInsights.placeholder.empty.entities': 'No hay entidades para este documento',
        'core.text.insights': 'Perspectivas',
        'core.text.keyData': 'Llave de datos',
        'core.text.chat': 'Charlar',
        'core.text.capital.page': 'Página',
        'core.text.of': 'de',
        'core.text.matches': 'Partidos',
        'core.text.reset': 'Reiniciar',
        'core.text.confirm': 'Confirmar',
        'core.text.placeholder.loadingConfig': 'Carga de configuración',
    },
};

export default es;
