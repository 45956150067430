const en = {
    translation: {
        'core.text.login': 'Log In',
        'core.text.requestInvite': 'Request an Invite',
        'core.text.solutions': 'Solutions',
        'core.text.product': 'Product',
        'core.text.pricePlans': 'Price Plans',
        'core.text.type': 'Document Type',
        'core.text.filename': 'File Name',
        'core.text.agreementType': 'Agreement Type',
        'core.text.checkbox.exactMatches': 'exact matches only',
        'core.text.cancel': 'Cancel',
        'core.text.delete': 'Delete',
        'core.text.title': 'Title',
        'core.text.name': 'Name',
        'core.text.tags': 'Tags',
        'core.text.settings': 'Settings',
        'core.text.help': 'Help',
        'core.text.logout': 'Log Out',
        'core.text.definition': 'Definition',
        'core.text.documents': 'Documents',
        'core.text.placeholder.searchFilters': 'Search for filters',
        'feature.text.header':
            'Melior AI makes navigating stacks of legal contracts and other common business documents a breeze!',
        'sidebar.text.item.advancedSearch': 'Advanced Search',
        'sidebar.text.item.savedQueries': 'Saved Queries',
        'sidebar.text.item.clauseLibrary': 'Clause Library',
        'sidebar.text.item.insightTemplates': 'Insight Templates',
        'sidebar.text.item.approvedClauses': 'Approved Clauses',
        'sidebar.text.item.comparisons': 'Comparisons',
        'sidebar.text.item.workflows': 'Workflows',
        'sidebar.text.item.collaboration': 'Collaboration',
        'documents.table.column.fileName': 'File Name',
        'documents.table.column.status': 'Status',
        'documents.action.uploadDocument': 'Upload Document',
        'documents.action.getInsights': 'Get Insights',
        'documents.action.uploading': 'Uploading...',
        'documents.action.finalizing': 'Finalizing...',
        'advancedSearch.table.column.textMatches': 'Text matches',
        'advancedSearch.table.empty': 'No results',
        'advancedSearch.action.saveQuery': 'Save Query',
        'advancedSearch.action.addFilter': 'Add Filter',
        'advancedSearch.action.clearAll': 'Clear All',
        'advancedSearch.modal.filterList.title': 'Filter list',
        'advancedSearch.modal.filterList.description':
            'Add one or multiple clauses to refine your query',
        'queries.table.title': 'Queries',
        'queries.table.column.description': 'Description',
        'clauseLibrary.table.title': 'Clause Library',
        'clauseLibrary.action.addClause': 'Add Clause',
        'workflows.table.title': 'Workflows',
        'workflows.table.row.action': 'Launch',
        'workflows.action': 'Workflow Editor',
        'workflows.placeholder.entertag': 'Enter a tag',
        'comparisons.page.title': 'Comparisons',
        'comparisons.page.action.create': 'Create Comparison',
        'comparisons.page.action.viewComparisons': 'View Comparisons',
        'comparisons.modal.title.create': 'Create Comparison',
        'comparisons.modal.title.clauseList': 'Clause List',
        'comparisons.modal.title.reviewDetails': 'Comparison: Review Details',
        'comparisons.modal.placeholder.comparisonName': 'Comparison name',
        'comparisons.modal.field.error.required': 'This field is required',
        'comparisons.modal.placeholder.addDescription': 'Add description',
        'comparisons.modal.field.label.yourSavedQueries': 'Your saved queries',
        'core.text.clauses': 'Clauses',
        'core.text.back': 'Back',
        'core.text.next': 'Next',
        'core.text.update': 'Update',
        'core.text.create': 'Create',
        'comparisons.page.placeholder.empty': 'No comparisons found',
        'core.text.error': 'An error has occurred',
        'workflows.page.placeholder.loading': 'Loading workflows',
        'workflows.page.placeholder.empty': 'No workflows found',
        'clauses.page.placeholder.loading': 'Loading clause library',
        'comparisons.page.placeholder.loading': 'Loading comparisons',
        'documents.page.placeholder.loading': 'Loading documents',
        'documents.page.placeholder.empty': 'No documents found',
        'documents.page.delete.confirm.singular': 'Are you sure you want to delete the following document?',
        'documents.page.delete.confirm.plural': 'Are you sure you want to delete the following documents?',
        'documents.page.delete.confirm.message': 'This action cannot be undone.',
        'documents.page.delete.loading.plural': 'Deleting documents...',
        'documents.page.delete.loading.singular': 'Deleting document...',
        'documents.page.alert.error.plural': 'Error deleting documents.',
        'documents.page.alert.success.plural': 'Documents deleted successfully.',
        'documents.page.alert.error.singular': 'Error deleting document.',
        'documents.page.alert.success.singular': 'Document deleted successfully.',
        'queries.page.placeholder.loading': 'Loading queries',
        'queries.page.placeholder.empty':
            'You have no saved queries but you can search and save your\n' +
            '                                        first one from the',
        'core.text.page': 'page',
        'getinsights.action.editSelection': 'Edit Selection',
        'core.text.select': 'Select',
        'getInsights.action.showClauses': 'Show clauses',
        'getInsights.action.showKeyData': 'Show key data',
        'getInsights.title.insightList': 'Insight List',
        'getInsights.placeholder.empty.entities': 'There are no entities for this document',
        'core.text.insights': 'Insights',
        'core.text.keyData': 'Key Data',
        'core.text.chat': 'Chat',
        'core.text.capital.page': 'Page',
        'core.text.of': 'of',
        'core.text.matches': 'Matches',
        'core.text.reset': 'Reset',
        'core.text.confirm': 'Confirm',
        'core.text.placeholder.loadingConfig': 'Loading configuration'
    },
};

export default en;
