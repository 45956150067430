import { Box, LinearProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ClientPagePreloader() {
    const { t } = useTranslation();
    return (
        <div
            data-testid="client-page-preloader"
            style={{
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Box
                style={{
                    width: '50%',
                    padding: '2em',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
                    borderRadius: '10px',
                    margin: 'auto',
                }}
            >
                <Typography
                    variant="h5"
                    align="center"
                    style={{
                        textAlign: 'left',
                        marginBottom: '.3em',
                    }}
                >
                    {t('core.text.placeholder.loadingConfig')}...
                </Typography>
                <LinearProgress />
            </Box>
        </div>
    );
}
