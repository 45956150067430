import { useContext } from 'react';
import Container from '../UI/Common/Container';
import { Text } from '../UI/Typography';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../pages/Client/GetInsights/context/getInsightsContext';
import { useTranslation } from 'react-i18next';

const PDFViewerPageIndicator = () => {
    const { currentPage, numPages, isDocumentLoaded } = useContext(
        GetInsightsContext
    ) as GetInsightsContextType;
    const { t } = useTranslation();
    if (!isDocumentLoaded) return <></>;
    return (
        <Container fullWidth textAlign="center" topOuterSpacing={0.3}>
            <Text>
                {t('core.text.capital.page')} {currentPage + 1} {t('core.text.of')} {numPages}
            </Text>
        </Container>
    );
};

export default PDFViewerPageIndicator;
