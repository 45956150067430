import { Box, Button, Divider, InputAdornment, Modal } from '@mui/material';
import { H5, Text } from '../../UI/Typography';
import Container from '../../UI/Common/Container';
import Flex from 'styled-flex-component';
import {
    AddCircleOutline,
    Close,
    CloseOutlined,
    RemoveCircleOutline,
    SearchOutlined,
} from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { StyledSearchTextField } from '../SearchSectionInput/StyledTextField';
import {
    AdvancedSearchContext,
    AdvancedSearchContextType,
} from '../../../pages/Client/AdvancedSearch/context/advancedSearchContext';
import { useClauses } from '../../../hooks/useClauses';
import { useTranslation } from 'react-i18next';

interface IAddFilterModalProps {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    onAddFilters: (vals: string[]) => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '10px',
};

export default function AddFilterModal({ isOpen, setIsOpen, onAddFilters }: IAddFilterModalProps) {
    const { clauses } = useClauses();
    const { t } = useTranslation();
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const [clauseList, setClauseList] = useState<string[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const { filtersAdded } = useContext(AdvancedSearchContext) as AdvancedSearchContextType;

    const selectFilter = (filter: string) => {
        if (selectedFilters.find((option) => option === filter)) {
            const temp = [...selectedFilters];
            temp.splice(
                selectedFilters.findIndex((option) => option === filter),
                1
            );
            setSelectedFilters(temp);
            return;
        }

        setSelectedFilters([...selectedFilters, filter]);
    };

    const isFilterAdded = (clause: string): boolean => {
        return Boolean(selectedFilters.find((option) => option === clause));
    };

    const filterClauses = (filterText: string) => {
        if (!filterText) {
            setClauseList(clauses);
            return;
        }

        const tempList: string[] = [];
        clauses.map((clause) => {
            if (clause.toLowerCase().includes(filterText.toLowerCase())) {
                tempList.push(clause);
            }

            return clause;
        });

        setClauseList(tempList);
    };

    useEffect(() => {
        const tempFilters: string[] = [];
        for (let i = 0; i < filtersAdded.length; i++) {
            tempFilters.push(filtersAdded[i].filterLabel);
        }

        setSelectedFilters(tempFilters);
    }, [filtersAdded]);

    useEffect(() => {
        if (clauses && clauses.length) {
            setClauseList(clauses);
        }
    }, [clauses]);

    return (
        <Modal
            open={isOpen}
            aria-labelledby="add-filter-modal-title"
            aria-describedby="add-filter-modal-description"
        >
            <Box sx={style}>
                <Flex justifyBetween alignTop>
                    <H5>{t('advancedSearch.modal.filterList.title')}</H5>
                    <Container onClick={() => setIsOpen(false)}>
                        <CloseOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }} />
                    </Container>
                </Flex>
                <Container topOuterSpacing={0.7}>
                    <Text color="#0D18328A">
                        {t('advancedSearch.modal.filterList.description')}
                    </Text>
                </Container>
                <Container topOuterSpacing={1.0625}>
                    <Divider />
                </Container>

                <Container maxHeight="300px" overflow="scroll" topOuterSpacing={1.6875}>
                    <Container bottomOuterSpacing={1.6875}>
                        <StyledSearchTextField
                            autoFocus
                            autoComplete="off"
                            fullWidth
                            placeholder={t('core.text.placeholder.searchFilters')}
                            size="small"
                            value={searchText}
                            sx={{ background: 'rgba(0, 0, 0, 0.06)' }}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                filterClauses(e.target.value);
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchText && (
                                            <Container
                                                topOuterSpacing={0.4}
                                                cursor="pointer"
                                                onClick={() => {
                                                    setSearchText('');
                                                    filterClauses('');
                                                }}
                                            >
                                                <Close fontSize="small" />
                                            </Container>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Container>
                    {clauseList &&
                        Boolean(clauseList.length) &&
                        clauseList.map((clause, index) => (
                            <Container
                                borderTop="1px solid rgba(13, 24, 50, 0.12)"
                                borderLeft="1px solid rgba(13, 24, 50, 0.12)"
                                borderRight="1px solid rgba(13, 24, 50, 0.12)"
                                borderBottom={
                                    clauseList.length - 1 === index
                                        ? '1px solid rgba(13, 24, 50, 0.12)'
                                        : 'none'
                                }
                                leftInnerSpacing={1}
                                rightInnerSpacing={1.375}
                                topInnerSpacing={1.25}
                                bottomInnerSpacing={1.25}
                                cursor="pointer"
                                onClick={() => selectFilter(clause)}
                                backgroundColor={
                                    isFilterAdded(clause) ? 'rgba(16, 185, 129, 0.3)' : ''
                                }
                            >
                                <Flex alignCenter justifyBetween>
                                    <Text color="rgba(13, 24, 50, 0.87)" customFontSize={1}>
                                        {clause}
                                    </Text>
                                    {isFilterAdded(clause) && (
                                        <RemoveCircleOutline
                                            sx={{ color: 'rgba(13, 24, 50, 0.54)' }}
                                        />
                                    )}
                                    {!isFilterAdded(clause) && (
                                        <AddCircleOutline
                                            sx={{ color: 'rgba(13, 24, 50, 0.54)' }}
                                        />
                                    )}
                                </Flex>
                            </Container>
                        ))}
                </Container>

                <Container topOuterSpacing={2.75}>
                    <Divider />
                </Container>

                <Container topInnerSpacing={1}>
                    <Flex justifyEnd>
                        <Container>
                            <Button onClick={() => setIsOpen(false)}>
                                <Text customFontFamily="Poppins" customFontWeight={500} uppercase>
                                    {t('core.text.cancel')}
                                </Text>
                            </Button>
                        </Container>
                        <Container leftOuterSpacing={0.5}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    onAddFilters(selectedFilters);
                                    setSelectedFilters([]);
                                }}
                            >
                                <Text customFontFamily="Poppins" customFontWeight={500} uppercase>
                                    {t('advancedSearch.action.addFilter')}
                                </Text>
                            </Button>
                        </Container>
                    </Flex>
                </Container>
            </Box>
        </Modal>
    );
}
