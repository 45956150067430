import { Card, CardContent, CardHeader, Modal, ModalProps } from '@mui/material';
import useAnnouncementBannerStyles from './useAnnouncementBannerStyles';
import { IAnnouncementTypes } from '../../@types/Announcement';

interface Props {
    title?: string;
    message?: string;
    type?: IAnnouncementTypes| string;
    open: boolean;
    onClose: ModalProps['onClose'];
}

export default function AnnouncementBannerModal({ title, message, open, onClose, type }: Props) {
    const theme = useAnnouncementBannerStyles();

    return (
        <Modal open={open} onClose={onClose}>
            <Card
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '1em',
                    width: '50%',
                    ...theme[type as string],
                }}
            >
                <CardHeader title={title} />
                <CardContent
                    style={{
                        maxHeight: '50vh',
                        overflowY: 'auto',
                    }}
                >
                    {message}
                </CardContent>
            </Card>
        </Modal>
    );
}
