import SearchSectionFilterChip from '../FilterChip';
import Flex from 'styled-flex-component';
import {
    Button,
    Divider,
    FormControl,
    InputAdornment,
    InputLabel,
    ListSubheader,
    MenuItem,
} from '@mui/material';
import { StyledFilterTypeSelector } from './StyledFilterSelect.styled';
import Container from '../../UI/Common/Container';
import { IAdvancedSearchPageSearchFilter } from '../../../pages/Client/AdvancedSearch/Page';
import { Text } from '../../UI/Typography';
import { useContext, useMemo, useState } from 'react';
import { SearchOutlined } from '@mui/icons-material';
import { StyledSearchTextField } from '../SearchSectionInput/StyledTextField';
import { useDocumentTypes } from '../../../hooks/useDocumentTypes';
import { Theme } from '../../../theme';
import {
    AdvancedSearchContext,
    AdvancedSearchContextType,
} from '../../../pages/Client/AdvancedSearch/context/advancedSearchContext';
import { useTranslation } from 'react-i18next';

interface ISearchFiltersProps {
    setAgreementType: (type: string) => void;
    setFiltersAdded: (clauses: IAdvancedSearchPageSearchFilter[]) => void;
    setIsFiltersOverlayOpened: (val: boolean) => void;
    setSearchText: (val: string) => void;
    setIsAddFilterModalOpen: (val: boolean) => void;
}

const SearchFiltersPageSection = ({
    setAgreementType,
    setFiltersAdded,
    setIsFiltersOverlayOpened,
    setSearchText,
    setIsAddFilterModalOpen,
}: ISearchFiltersProps) => {
    const { t } = useTranslation();
    const [selectSearchText, setSelectSearchText] = useState('');
    const { filtersAdded, agreementType, setSelectedClauseIndex } = useContext(
        AdvancedSearchContext
    ) as AdvancedSearchContextType;

    const containsText = (text, searchText) =>
        text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    const { docTypes } = useDocumentTypes();

    const displayedOptions = useMemo(
        () =>
            docTypes &&
            ['All'].concat(docTypes).filter((option) => containsText(option, selectSearchText)),
        [selectSearchText, docTypes]
    );
    const handleChange = (e) => {
        const selectedOption = e.target.value;

        if (selectedOption === 'All') return;

        const clause = filtersAdded.find((filter) => filter.filterLabel === selectedOption);
        if (!clause) {
            setFiltersAdded([
                ...filtersAdded,
                {
                    filterLabel: 'Type',
                    value: e.target.value,
                    display: `is ${e.target.value}`,
                },
            ]);
        }
        setAgreementType('');
    };

    const clearFilters = () => {
        setFiltersAdded([]);
        setSearchText('');
    };

    const onFilterRemove = (index: number) => {
        const temp = [...filtersAdded];
        temp.splice(index, 1);
        setFiltersAdded(temp);
    };

    return (
        <Flex alignTop>
            <Container outerSpacing={0.25}>
                <FormControl
                    size="small"
                    fullWidth
                    sx={{ borderColor: 'rgba(16, 185, 129, 0.3)', marginTop: '5px' }}
                >
                    <InputLabel
                        shrink={false}
                        sx={{
                            color: `${Theme.primaryDark} !important`,
                            fontSize: '13px',
                            marginTop: '-3px',
                        }}
                    >
                        {t('core.text.agreementType')}
                    </InputLabel>
                    <StyledFilterTypeSelector
                        style={{ fontSize: '13px', height: '32px' }}
                        value={agreementType}
                        onChange={handleChange}
                    >
                        <ListSubheader>
                            <StyledSearchTextField
                                size="small"
                                autoComplete="off"
                                placeholder="Start typing"
                                fullWidth
                                sx={{
                                    background: 'rgba(0, 0, 0, 0.06)',
                                }}
                                value={selectSearchText}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => setSelectSearchText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key !== 'Escape') {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                    }
                                }}
                            />
                        </ListSubheader>
                        {displayedOptions &&
                            displayedOptions.length &&
                            displayedOptions.map((clause) => (
                                <MenuItem sx={{ fontSize: 14 }} value={clause}>
                                    {clause}
                                </MenuItem>
                            ))}
                    </StyledFilterTypeSelector>
                </FormControl>
            </Container>

            <Divider
                orientation="vertical"
                flexItem
                style={{ marginLeft: '11px', marginRight: '11px' }}
            />

            <Container outerSpacing={0.25}>
                <Flex justifyEvenly alignCenter wrap row>
                    {filtersAdded.map((clause, index) => (
                        <Container
                            rightOuterSpacing={0.5625}
                            topOuterSpacing={0.25}
                            bottomOuterSpacing={0.25}
                        >
                            <SearchSectionFilterChip
                                index={index}
                                onFilterRemove={onFilterRemove}
                                clause={clause}
                                setIsFiltersOverlayOpened={setIsFiltersOverlayOpened}
                                setSelectedClauseIndex={setSelectedClauseIndex}
                            />
                        </Container>
                    ))}
                    <Button onClick={() => setIsAddFilterModalOpen(true)}>
                        <Text customFontFamily="Poppins" customFontWeight={500} uppercase>
                            {t('advancedSearch.action.addFilter')}
                        </Text>
                    </Button>
                    <Button onClick={clearFilters}>
                        <Text customFontFamily="Poppins" customFontWeight={500} uppercase>
                            {t('advancedSearch.action.clearAll')}
                        </Text>
                    </Button>
                </Flex>
            </Container>
        </Flex>
    );
};

export default SearchFiltersPageSection;
