import { useEffect, useState } from 'react';
import { COUNTRIES } from '../../../../constants';
import i18n from '../../../../i18n';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Text } from '../../Typography';
import updateUserMetadata from '../../../../api/frontegg/updateUserMetadata';
import getFrontEggUser from '../../../../api/frontegg/getFrontEggUser';
import { FrontEggUser } from '../../../../@types/User';
import { CountryCode } from '../../../../enums/CountryCode';

const menuId = 'language-menu';

export const LanguageMenu = () => {
    const currentLanguage = i18n.language;

    const [user, setUser] = useState<FrontEggUser>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);

    const handleClose = (language) => {
        setAnchorEl(null);
        i18n.changeLanguage(language);
        updateUserMetadata({ ...user?.metadata, language });
    };

    async function initUser() {
        try {
            const frontEggUser = await getFrontEggUser();
            i18n.changeLanguage(
                (frontEggUser?.metadata?.language as CountryCode) ?? CountryCode.ENGLISH
            );
            setUser(user);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        initUser();
    }, []);

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id={menuId}
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    'overflow': 'visible',
                    'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    'mt': 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 22,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {Object.keys(COUNTRIES).map((countryCode) => (
                <MenuItem
                    key={countryCode}
                    selected={currentLanguage === countryCode}
                    onClick={() => handleClose(countryCode)}
                >
                    {COUNTRIES[countryCode].icon}{' '}
                    <Text style={{ marginLeft: '8px' }}>{COUNTRIES[countryCode].language}</Text>
                </MenuItem>
            ))}
        </Menu>
    );

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <>
            <IconButton onClick={handleClick}>{COUNTRIES[currentLanguage].icon}</IconButton>
            {renderMenu}
        </>
    );
};

export default LanguageMenu;
