import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useLoginWithRedirect, useAuth } from '@frontegg/react';
import { SvgMeliorLogo3 } from '../../components/UI/icons/SvgMeliorLogo';
import { saveUserSessionData } from '../../utils/userSession';
import { User } from '@frontegg/redux-store/auth/interfaces';
import { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { isEmpty } from 'lodash';
import { Theme } from '../../theme';
import customerImgGreg from '../../images/Greg.png';
import customerImgMatt from '../../images/Matt.png';
import customerImgKeren from '../../images/Keren.png';
import customerImgLuis from '../../images/Luis.png';
import customerImgAlex from '../../images/Alex.png';
import customerImgAbi from '../../images/Abi.png';
import Carousel from 'react-material-ui-carousel';
import { Widget } from '@typeform/embed-react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
    const { isAuthenticated, user } = useAuth();
    const [displayForm, toggleDisplayForm] = useState<boolean>(false);
    const loginWithRedirect = useLoginWithRedirect();
    const { t } = useTranslation();
    const boxUserId = user?.metadata && JSON.parse(user?.metadata)?.boxUserId;

    const handleIsAuthenticated = () => {
        saveUserSessionData(user as User);

        if (isEmpty(boxUserId)) {
            window.location.href = `/initialize`;
        } else {
            window.location.href = `/client/dashboard`;
        }
    };

    const cards = [
        {
            testimony: `This powerful tool has transformed our workflow, 
        boosting efficiency and providing invaluable insights.
        The platform's ability to effortlessly extract and organize information 
        from various documents has saved us a tremendous amount of time and effort. 
        Its accuracy and advanced natural language processing 
        algorithms enable us to uncover hidden patterns and make informed decisions.`,
            customerInfo: {
                image: customerImgMatt,
                name: 'Matthew Binns',
                position: 'Managing Director',
                company: 'Utilipay',
            },
        },
        {
            testimony: `Melior has completely transformed the way I analyze documents. 
        It's easy to use and has saved me so much time. 
        I highly recommend it!`,
            customerInfo: {
                image: customerImgGreg,
                name: 'Greg Morris',
                position: 'CEO',
                company: 'Sebata Holdings',
            },
        },
        {
            testimony: `Melior has totally upended my approach to document analysis. 
        It's intuitive, it's user-friendly, 
        and it has clawed back many hours of my time. 
        In my view, you'd be hard-pressed to find something better. 
        Do yourself a favor and give it a shot!`,
            customerInfo: {
                image: customerImgKeren,
                name: 'Keren Cohen',
                position: 'COO',
                company: 'Midos Commercial Properties, Inc.',
            },
        },
        {
            testimony: `Love it!`,
            customerInfo: {
                image: customerImgAlex,
                name: 'Alex Bouaziz',
                position: 'CEO & Co-Founder',
                company: 'Deel',
            },
        },
        {
            testimony: `Finally, an easy to use tool that reliably helps me find key data in all sorts of legal documents.`,
            customerInfo: {
                image: customerImgLuis,
                name: 'Luis Barreto',
                position: 'Head of Strategic Projects',
                company: 'Andbank Group',
            },
        },
        {
            testimony: `Incredible`,
            customerInfo: {
                image: customerImgAbi,
                name: 'Abi Mohamed',
                position: 'Tech Director',
                company: 'CGVentures',
            },
        },
        // Add more cards as needed
    ];

    const headerLinks = [
        {
            title: t('core.text.solutions'),
            target: 'https://www.melior.ai/#solutionsSection',
        },
        {
            title: t('core.text.product'),
            target: 'https://www.melior.ai/#productsSection',
        },
        {
            title: t('core.text.pricePlans'),
            target: 'https://www.melior.ai/#pricingSection',
        },
    ];
    useEffect(() => {
        if (document.querySelector('#launcher-frame')) {
            document.querySelector<HTMLElement>('#launcher-frame')!.style.display = 'none';
        }

        if (isAuthenticated) handleIsAuthenticated();
    }, [isAuthenticated, loginWithRedirect, displayForm]);

    function login() {
        loginWithRedirect();
    }

    return (
        <div>
            <AppBar
                style={{
                    backgroundColor: Theme.whiteColor,
                }}
                position="relative"
            >
                <Toolbar>
                    <SvgMeliorLogo3 />
                    {headerLinks.map((link, i) => (
                        <Link
                            href={link.target}
                            marginLeft="30px"
                            fontWeight="700"
                            color="text.secondary"
                            underline="none"
                            sx={{ pl: 2 }}
                            key={i}
                            noWrap
                        >
                            {link.title}
                        </Link>
                    ))}
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex', p: 5 }}>
                <Box
                    sx={{
                        width: '50%',
                        p: 7,
                        backgroundColor: '#0D1832',
                        borderRadius: '30px',
                        minHeight: '65vh',
                    }}
                >
                    <Typography
                        align="left"
                        color="white"
                        fontSize="32px"
                        fontWeight="600"
                        gutterBottom
                    >
                        {t('feature.text.header')}
                    </Typography>
                    <Box
                        sx={{
                            bgcolor: '#2C3449',
                            p: 3,
                            mt: 6,
                            borderRadius: 5,
                            height: 'fit-content',
                        }}
                    >
                        <Carousel>
                            {cards.map((card, i) => (
                                <Box key={i}>
                                    <Typography
                                        variant="body1"
                                        color="#BDC0C7"
                                        fontWeight="600"
                                        fontSize="18px"
                                        paragraph
                                    >
                                        {`"${card.testimony}"`}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 40,
                                                width: 40,
                                                mr: 2,
                                                borderRadius: '50%',
                                                alignSelf: 'center',
                                            }}
                                            alt="Testimony Customer Image"
                                            src={card.customerInfo.image}
                                        />
                                        <span>
                                            <Typography color="white" fontSize="18px">
                                                {card.customerInfo.name}
                                            </Typography>
                                            <Typography fontSize="16px" color="#5C6273">
                                                {card.customerInfo.position},{' '}
                                                {card.customerInfo.company}
                                            </Typography>
                                        </span>
                                    </Box>
                                </Box>
                            ))}
                        </Carousel>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 'fit-content',
                        alignContent: 'center',
                    }}
                >
                    {displayForm && (
                        <span
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                height: '70vh',
                                padding: '10px',
                            }}
                        >
                            <IconButton
                                aria-label="back"
                                color="primary"
                                onClick={() => {
                                    toggleDisplayForm(false);
                                }}
                            >
                                <ArrowBackIosNewIcon />
                            </IconButton>
                            <Widget id="N9V4ZRey" style={{ height: '90%', width: '100%' }} />
                        </span>
                    )}
                    {!displayForm && (
                        <Stack
                            direction="row"
                            sx={{ mt: 35 }}
                            spacing={2}
                            justifyContent="center"
                            alignContent="center"
                        >
                            <Button
                                variant="outlined"
                                style={{ textTransform: 'none', width: '30%' }}
                                color="secondary"
                                onClick={() => {
                                    toggleDisplayForm(!displayForm);
                                }}
                                size="large"
                            >
                                {t('core.text.requestInvite')}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={login}
                                style={{ textTransform: 'none', width: '30%' }}
                                size="large"
                            >
                                {t('core.text.login')}
                            </Button>
                        </Stack>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default LoginPage;
