const fr = {
    translation: {
        'core.text.login': 'Connexion',
        'core.text.requestInvite': 'Demandez une invitation',
        'core.text.solutions': 'Solutions',
        'core.text.product': 'Produit',
        'core.text.pricePlans': 'Plans de prix',
        'core.text.type': 'Type de document',
        'core.text.filename': 'Nom de fichier',
        'core.text.agreementType': "Type d'accord",
        'core.text.checkbox.exactMatches': 'correspondances exactes uniquement',
        'core.text.cancel': 'Annuler',
        'core.text.delete': 'Supprimer',
        'core.text.title': 'Titre',
        'core.text.name': 'Nom',
        'core.text.tags': 'Mots clés',
        'core.text.settings': 'Paramètres',
        'core.text.help': 'Aide',
        'core.text.logout': 'Se déconnecter',
        'core.text.definition': 'Définition',
        'core.text.documents': 'Documents',
        'core.text.placeholder.searchFilters': 'Rechercher des filtres',
        'feature.text.header':
            "Melior Ai fait de la navigation de piles de contrats juridiques et d'autres documents commerciaux communs un jeu d'enfant!",
        'sidebar.text.item.advancedSearch': 'Recherche Avancée',
        'sidebar.text.item.savedQueries': 'Requêtes enregistrées',
        'sidebar.text.item.clauseLibrary': 'Bibliothèque de clauses',
        'sidebar.text.item.insightTemplates': 'Modèles de perspicacité',
        'sidebar.text.item.approvedClauses': 'Clauses approuvées',
        'sidebar.text.item.comparisons': 'Comparaisons',
        'sidebar.text.item.workflows': 'Flux de travail',
        'sidebar.text.item.collaboration': 'Collaboration',
        'documents.table.column.fileName': 'Nom de fichier',
        'documents.table.column.status': 'Statut',
        'documents.action.uploadDocument': 'Télécharger le document',
        'documents.action.getInsights': 'Obtenir des idées',
        'documents.action.uploading': 'Téléchargement...',
        'documents.action.finalizing': 'Finalisation...',
        'advancedSearch.table.column.textMatches': 'Correspondance au texte',
        'advancedSearch.table.empty': 'Aucun résultat',
        'advancedSearch.action.saveQuery': 'Sauver la requête',
        'advancedSearch.action.addFilter': 'Ajouter le filtre',
        'advancedSearch.action.clearAll': 'Tout effacer',
        'advancedSearch.modal.filterList.title': 'Liste de filtre',
        'advancedSearch.modal.filterList.description':
            'Ajoutez une ou plusieurs clauses pour affiner votre requête',
        'queries.table.title': 'Requêtes',
        'queries.table.column.description': 'Description',
        'clauseLibrary.table.title': 'Bibliothèque de clause',
        'clauseLibrary.action.addClause': 'Ajouter la clause',
        'workflows.table.title': 'Flux de travail',
        'workflows.table.row.action': 'Lancement',
        'workflows.action': 'Éditeur de workflow',
        'workflows.placeholder.entertag': 'Entrez une balise',
        'comparisons.page.title': 'Comparaisons',
        'comparisons.page.action.create': 'Créer une comparaison',
        'comparisons.page.action.viewComparisons': 'Voir les comparaisons',
        'comparisons.modal.title.create': 'Créer une comparaison',
        'comparisons.modal.title.clauseList': 'Liste de clauses',
        'comparisons.modal.title.reviewDetails': 'Comparaison: Détails de la révision',
        'comparisons.modal.placeholder.comparisonName': 'Nom de comparaison',
        'comparisons.modal.field.error.required': 'Ce champ est obligatoire',
        'comparisons.modal.placeholder.addDescription': 'Ajouter une description',
        'comparisons.modal.field.label.yourSavedQueries': 'Vos requêtes enregistrées',
        'core.text.clauses': 'Clauses',
        'core.text.back': 'Dos',
        'core.text.next': 'Suivant',
        'core.text.update': 'Mise à jour',
        'core.text.create': 'Créer',
        'comparisons.page.placeholder.empty': 'Aucune comparaison trouvée',
        'core.text.error': 'Une erreur est survenue',
        'workflows.page.placeholder.loading': 'Chargement des workflows',
        'workflows.page.placeholder.empty': 'Aucun flux de travail trouvé',
        'clauses.page.placeholder.loading': 'Bibliothèque de clause de chargement',
        'comparisons.page.placeholder.loading': 'Comparaisons de chargement',
        'documents.page.placeholder.loading': 'Chargement des documents',
        'documents.page.placeholder.empty': 'Aucun document trouvé',
        'documents.page.delete.confirm.singular': 'Etes-vous sûr de vouloir supprimer le document suivant?',
        'documents.page.delete.confirm.plural': 'Êtes-vous sûr de vouloir supprimer les documents suivants?',
        'documents.page.delete.confirm.message': 'Cette action ne peut pas être annulée.',
        'documents.page.delete.loading.plural': 'Suppression de documents...',
        'documents.page.delete.loading.singular': 'Suppression du document...',
        'documents.page.alert.error.plural': 'Erreur lors de la suppression des documents.',
        'documents.page.alert.success.plural': 'Documents supprimés avec succès.',
        'documents.page.alert.error.singular': 'Erreur lors de la suppression du document.',
        'documents.page.alert.success.singular': 'Document supprimé avec succès.',
        'queries.page.placeholder.loading': 'Chargement des requêtes',
        'queries.page.placeholder.empty':
            "Vous n'avez pas de requêtes enregistrées mais vous pouvez rechercher et enregistrer votre\n" +
            '                                        le premier du',
        'core.text.page': 'page',
        'getinsights.action.editSelection': 'Modifier la sélection',
        'core.text.select': 'Sélectionner',
        'getInsights.action.showClauses': 'Montrer les clauses',
        'getInsights.action.showKeyData': 'Afficher les données clés',
        'getInsights.title.insightList': 'Liste des informations',
        'getInsights.placeholder.empty.entities': "Il n'y a pas d'entités pour ce document",
        'core.text.insights': 'Connaissances',
        'core.text.keyData': 'Données clé',
        'core.text.chat': 'Chat',
        'core.text.capital.page': 'Page',
        'core.text.of': 'de',
        'core.text.matches': 'Allumettes',
        'core.text.reset': 'Réinitialiser',
        'core.text.confirm': 'Confirmer',
        'core.text.placeholder.loadingConfig': 'Configuration de chargement',
    },
};

export default fr;
