import Select from '@mui/material/Select';
import styled from 'styled-components';

export const StyledFilterTypeSelector = styled(Select)`
    min-width: 150px;
    background: rgba(16, 185, 129, 0.3);
    border-radius: 150px !important;

    fieldset {
        border: none;
    }
`;
