import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker, LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDayjs as AdapterDayjsPro } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { IAdvancedSearchPageSearchFilter } from '../../../pages/Client/AdvancedSearch/Page';
import { MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import Container from '../../UI/Common/Container';
import { DateFilterOperator, formDatePayload } from './helpers';
import dayjs from 'dayjs';
import { isArray } from 'lodash';
import { getSelectorValue } from '../../../pages/Client/AdvancedSearch/helpers';

interface ISearchDateFilterFieldProps {
    clause: IAdvancedSearchPageSearchFilter;
    filtersAdded: IAdvancedSearchPageSearchFilter[];
    setFiltersAdded: (clauses: IAdvancedSearchPageSearchFilter[]) => void;
}

const dateOptions = Object.values(DateFilterOperator);

function getDateVal(clause) {
    if (!clause.value) return null;

    const keys = Object.keys(clause.value);

    if (keys.length === 2) {
        const from = clause.value['gte'];
        const to = clause.value['lte'];
        return [dayjs(from), dayjs(to)];
    }

    return clause.value;
}

const SearchDateFilterField = ({
    clause,
    filtersAdded,
    setFiltersAdded,
}: ISearchDateFilterFieldProps) => {
    const [selectorValue, setSelectorValue] = useState<string>(getSelectorValue(clause));
    const [dateVal, setDateVal] = useState<any>(getDateVal(clause));
    const [oneMonthForwardRange] = useState<any>([dayjs(), dayjs().add(1, 'month')]);

    const onDateRangeChange = () => {
        if (!(dateVal && selectorValue)) return;
        if (!isArray(dateVal)) return;

        const dates: string[] = []; // 0 index is `from` and 1 index is `to`

        //this iteration will only have two iterations for from and to
        dateVal.map((dateValItem) => {
            if (!dateValItem) return;
            const year = dateValItem['$y'];
            const month = dateValItem['$M'] + 1;
            const day = dateValItem['$D'];
            const date = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');
            dates.push(date);
        });

        if (dates.length !== 2) return;

        const newArr: IAdvancedSearchPageSearchFilter[] = filtersAdded.map((filter) => {
            if (filter.filterLabel === clause.filterLabel) {
                filter.display = `${selectorValue} ${dates[0]} and ${dates[1]}`;
                filter.value = formDatePayload(selectorValue, dates);
            }
            return filter;
        }) as unknown as IAdvancedSearchPageSearchFilter[];
        setFiltersAdded(newArr);
    };

    const onChange = () => {
        if (!(dateVal && selectorValue)) return;
        const year = dateVal['$y'];
        const month = dateVal['$M'] + 1;
        const day = dateVal['$D'];
        const date = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');

        const newArr: IAdvancedSearchPageSearchFilter[] = filtersAdded.map((filter) => {
            if (filter.filterLabel === clause.filterLabel) {
                filter.display = `${selectorValue} ${date}`;
                filter.value = formDatePayload(selectorValue, date);
            }
            return filter;
        }) as unknown as IAdvancedSearchPageSearchFilter[];
        setFiltersAdded(newArr);
    };

    useEffect(() => {
        if (selectorValue === DateFilterOperator.IS_BETWEEN) {
            onDateRangeChange();
            return;
        }

        if (isArray(dateVal)) {
            setDateVal(dayjs(dateVal[0]));
        }

        onChange();
    }, [dateVal, selectorValue]);

    return (
        <>
            <Container leftOuterSpacing={1} rightInnerSpacing={1}>
                <Select
                    fullWidth
                    onChange={(e) => setSelectorValue(e.target.value)}
                    value={selectorValue}
                >
                    {dateOptions.map((option) => (
                        <MenuItem value={option}>{option}</MenuItem>
                    ))}
                </Select>
            </Container>

            {selectorValue !== DateFilterOperator.IS_BETWEEN && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        onChange={(e) => setDateVal(e)}
                        value={isArray(dateVal) ? dayjs(dateVal[0]) : dateVal}
                    />
                </LocalizationProvider>
            )}

            {selectorValue === DateFilterOperator.IS_BETWEEN && (
                <LocalizationProviderPro dateAdapter={AdapterDayjsPro}>
                    <Container innerSpacing={1}>
                        <DateRangePicker
                            defaultValue={isArray(dateVal) ? [dayjs(dateVal[0]), dayjs(dateVal[1])] : oneMonthForwardRange}
                            onChange={(e) => setDateVal(e)}/>
                    </Container>
                </LocalizationProviderPro>
            )}
        </>
    );
};

export default SearchDateFilterField;
