import { useCallback, useEffect, useMemo, useState } from 'react';
import mqtt, { MqttClient } from 'mqtt';
import { getUserConfig } from '../utils/userConfig';

interface Config {
    clientId: string;
    topics: string[];
}

interface WSEvents {
    onConnect?: () => void;
    onMessage?: (topic: string, message: object) => void;
}

export default function useWebsocket({ clientId, topics }: Config, events?: WSEvents) {
    const config = useCallback(() => getUserConfig(), [])();
    const websocketBaseUrl = useMemo(() => config?.websocketBaseUrl, [config]);

    if (!websocketBaseUrl)
        throw new Error('websocketBaseUrl is not defined. Please check Tenant metadata.');

    const wsClient = mqtt.connect(websocketBaseUrl, {
        clientId: `${clientId}-${Math.random().toString(16).substr(2, 8)}`,
        clean: false,
    });

    wsClient.on('connect', () => {
        events?.onConnect && events.onConnect();

        topics.forEach((topic) => {
            wsClient.subscribe(topic);
        });
    });

    wsClient.on('disconnect', () => {
        // console.log('Disconnected from', websocketBaseUrl);
    });

    wsClient.on('message', (topic, message) => {
        events?.onMessage && events.onMessage(topic, JSON.parse(message.toString()));
    });

    useEffect(() => {
        return () => {
            wsClient.end();
        };
    }, []);

    return { wsClient };
}
