import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../../components/UI/Navbar';
import Sidebar from '../../components/UI/Sidebar';
import Flex from 'styled-flex-component';
import Container from '../../components/UI/Common/Container';
import { useEffect, useState } from 'react';
import InsightsPage from './Insights';
import DocumentsPage from './Documents';
import SavedQueriesPage from './SavedQueries';
import { getUserSessionData } from '../../utils/userSession';
import { useJwt } from 'react-jwt';
import { isEmpty } from 'lodash';
import AdvancedSearchPage from './AdvancedSearch';
import { GetOrgMetadata } from '../../api/frontegg/org';
import { getUserConfig, saveUserConfig } from '../../utils/userConfig';
import ClientPagePreloader from './ClientPagePreloader';
import WorkflowsPage from './Workflows/Page';
import ClauseLibrary from './ClauseLibrary';
import ComparisonPage from './Comparison';
import ComparisonDetailPage from './ComparisonDetail';
import DomainUnreachableDialog from '../../components/UI/DomainUnreachableDialog';
import { fetchKnownDocTypes } from '../../api/config.api';
import AnnouncementBanner from '../../components/AnnouncementBanner/AnnouncementBanner';
import GetInsightsPage from './GetInsights';

const ClientPage = () => {
    const userData = getUserSessionData();
    const orgData = getUserConfig();
    const [isSideBarMaximized, setIsSideBarMaximized] = useState<boolean>(true);
    const [isConfigLoaded, setIsConfigLoaded] = useState(false);
    const [unreachableDomain, setUnreachableDomain] = useState<string>('');
    const navigate = useNavigate();
    const [showDefaultLayout, setShowDefaultLayout] = useState(true);
    const location = useLocation();

    const { isExpired } = useJwt(userData?.accessToken as string);

    useEffect(() => {
        if (isExpired || isEmpty(userData)) return navigate('/login');
    }, [isExpired]);

    useEffect(() => {
        setShowDefaultLayout(!/\/client\/insights\/.*/g.test(location.pathname));
    }, [location]);

    async function loadOrg() {
        try {
            const metadata = await GetOrgMetadata();
            saveUserConfig(metadata);
            setIsConfigLoaded(true);
        } catch (e) {
            console.error('Something went wrong', e);
        }
    }

    async function pingServers() {
        try {
            await pingMeliorBE();
        } catch (e: any) {
            console.log('e', e);
            setUnreachableDomain(e.domain);
        }
    }

    async function pingMeliorBE() {
        try {
            await fetchKnownDocTypes();
        } catch (e: any) {
            throw { domain: orgData?.backendBaseUrl as string };
        }
    }

    useEffect(() => {
        pingServers();
        loadOrg();
    }, []);

    return (
        <>
            <DomainUnreachableDialog unreachableDomain={unreachableDomain} />
            {!isConfigLoaded && <ClientPagePreloader />}
            {isConfigLoaded && (
                <>
                    {showDefaultLayout && <Navbar isSideBarMaximized={isSideBarMaximized} />}
                    <Flex>
                        {showDefaultLayout && (
                            <Sidebar
                                isSideBarMaximized={isSideBarMaximized}
                                setIsSideBarMaximized={setIsSideBarMaximized}
                            />
                        )}
                        <Container
                            width={
                                isSideBarMaximized && showDefaultLayout
                                    ? 'calc(100vw - 248px);'
                                    : showDefaultLayout
                                    ? 'calc(100vw - 80px);'
                                    : '100vw'
                            }
                            leftOuterSpacing={
                                isSideBarMaximized && showDefaultLayout
                                    ? '248px'
                                    : showDefaultLayout
                                    ? '80px'
                                    : '0px'
                            }
                            topOuterSpacing={showDefaultLayout ? 4.5371125 : 0}
                            height={showDefaultLayout ? 'calc(100vh - 72.5938px)' : '100vh'}
                            overflow="auto"
                            data-testid="client-page"
                        >
                            <Container
                                topInnerSpacing={showDefaultLayout ? 1.3 : 0}
                                bottomInnerSpacing={showDefaultLayout ? 1.3 : 0}
                                leftInnerSpacing={showDefaultLayout ? 1.5 : 0}
                                rightInnerSpacing={showDefaultLayout ? 1.5 : 0}
                            >
                                {showDefaultLayout && <AnnouncementBanner />}
                                <Routes>
                                    {/* <Route path="/dashboard"  element={<DashboardPage />} /> */}

                                    {/* Navigate to documents page temporarily */}
                                    <Route
                                        path="/dashboard"
                                        element={<Navigate to="../documents" />}
                                    />
                                    <Route path="/insights" element={<InsightsPage />} />
                                    <Route path="/saved-queries" element={<SavedQueriesPage />} />
                                    <Route path="/documents" element={<DocumentsPage />} />
                                    <Route path="/clause-library" element={<ClauseLibrary />} />
                                    <Route path="/comparisons" element={<ComparisonPage />} />
                                    <Route
                                        path="/comparison/:id"
                                        element={<ComparisonDetailPage />}
                                    />
                                    <Route path="/workflows" element={<WorkflowsPage />} />
                                    <Route
                                        path="/advanced-search"
                                        element={<AdvancedSearchPage />}
                                    />
                                    <Route path="/" element={<Navigate to="/client/dashboard" />} />
                                    <Route
                                        path="/insights/:documentId"
                                        element={<GetInsightsPage />}
                                    />
                                </Routes>
                            </Container>
                        </Container>
                    </Flex>
                </>
            )}
        </>
    );
};

export default ClientPage;
