import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { IDocument } from '../../../../@types/Document';
import { useMutation } from '@tanstack/react-query';
import { fullyRemoveDocument, removeDocument } from '../../../../api/files.api';
import { deleteDocument } from '../../../../api/box/files';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import Flex from 'styled-flex-component';
import { Text } from '../../../UI/Typography';
import { Theme } from '../../../../theme';

interface Props {
    documents: IDocument[];
    onClose: () => void;
    onDeleteSuccess: () => void;
    open: boolean;
}

export default function DeleteDocumentDialog(props: Props) {
    const { t } = useTranslation();
    const [deleteErrors, setDeleteErrors] = useState<string[]>([]);
    const { mutateAsync, isLoading } = useMutation(
        ['deleteDocument'],
        async (mutateParams: { documentName: string; documentId: string; boxFileId: string }) => {
            const { documentName, documentId, boxFileId } = mutateParams;
            try {
                await fullyRemoveDocument(documentId, boxFileId);
            } catch {
                setDeleteErrors(deleteErrors.concat(documentName));
            }
        }
    );

    const handleDelete = () => {
        Promise.all(
            props.documents.map((document) => {
                return mutateAsync({
                    documentName: document.name,
                    documentId: document.id ?? '',
                    boxFileId: document.box_details?.file_id as string,
                });
            })
        )
            .then(() => {
                if (!isEmpty(deleteErrors)) {
                    toast.error(
                        t(
                            `documents.page.alert.error.${
                                props.documents.length > 1 ? 'plural' : 'singular'
                            }`
                        )
                    );
                } else {
                    toast.success(
                        t(
                            `documents.page.alert.success.${
                                props.documents.length > 1 ? 'plural' : 'singular'
                            }`
                        )
                    );
                }
                props.onDeleteSuccess();
            })
            .catch(console.error.bind(console));
    };

    return (
        <Dialog open={props?.open}>
            <DialogTitle id="alert-dialog-title" style={{ fontSize: '18px' }}>
                {!isLoading && (
                    <>
                        {t(
                            `documents.page.delete.confirm.${
                                props.documents.length > 1 ? 'plural' : 'singular'
                            }`
                        )}
                    </>
                )}
                <div
                    style={{
                        marginTop: '15px',
                        overflow: 'scroll',
                        maxHeight: '150px',
                        background: 'whitesmoke',
                        padding: '10px',
                        borderRadius: '10px',
                    }}
                >
                    {props.documents.map((document, index) => (
                        <li key={index} style={{ fontSize: '14px', marginTop: '5px' }}>
                            {document.name}
                        </li>
                    ))}
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {!isLoading ? (
                        <>{t('documents.page.delete.confirm.message')}</>
                    ) : (
                        <Flex alignCenter justifyCenter style={{ margin: '10px' }}>
                            <CircularProgress
                                size={15}
                                style={{ marginRight: '10px' }}
                                color="primary"
                            />
                            <Text color={Theme.primary} customFontSize={1}>
                                {t(
                                    `documents.page.delete.loading.${
                                        props.documents.length > 1 ? 'plural' : 'singular'
                                    }`
                                )}
                            </Text>
                        </Flex>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!isLoading && (
                    <>
                        <Button onClick={props.onClose}>{t('core.text.cancel')}</Button>
                        <Button onClick={handleDelete} autoFocus color="error" disabled={isLoading}>
                            {t('core.text.delete')}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}
