import Box from '@mui/material/Box';
import Flex from 'styled-flex-component';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { StyledSearchTextField } from '../../AdvancedSearch/SearchSectionInput/StyledTextField';
import { useTranslation } from 'react-i18next';

interface IDocumentsTypeFilterSelectProps {
    setTags: (tags: string[]) => void;
}

const WorkflowsTagSelector = ({ setTags }: IDocumentsTypeFilterSelectProps) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ minWidth: 600 }}>
            <Flex alignCenter>
                <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    sx={{ minWidth: 600, maxWidth: '50%' }}
                    freeSolo
                    onChange={(e, value) => setTags(value)}
                    renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <StyledSearchTextField
                            {...params}
                            size="small"
                            autoComplete="off"
                            sx={{ background: 'rgba(0, 0, 0, 0.06)' }}
                            placeholder={t('workflows.placeholder.entertag')}
                        />
                    )}
                />
            </Flex>
        </Box>
    );
};

export default WorkflowsTagSelector;
