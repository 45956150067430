import { useQuery } from '@tanstack/react-query';
import { fetchComparisons } from '../api/comparison.api';

export function useComparisons() {
    const { data, ...rest } = useQuery({
        queryFn: () => fetchComparisons().then((res) => res),
        queryKey: ['comparisons'],
        refetchOnWindowFocus: false,
    });

    return {
        comparisons: data?.items,
        ...rest,
    };
}