import CardContainer from '../../../components/UI/Common/Card/Card';
import Container from '../../../components/UI/Common/Container';
import DocumentsTable from '../../../components/Documents/Tables/Documents';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Badge, Box, Chip, IconButton, InputAdornment, Tab, Tabs, Tooltip } from '@mui/material';
import Flex from 'styled-flex-component';
import { useDocuments } from '../../../hooks/useDocuments';
import ErrorPlaceholder from '../../../components/UI/Common/ErrorPlaceholder';
import Loading from '../../../components/UI/Common/Loading';
import Empty from '../../../components/UI/Common/Empty';
import BoxUploadDocumentButton from '../../../components/UI/BoxUploadDocumentButton';
import DocumentsTypeSelector from '../../../components/UI/DocumentTypeSelector.tsx/DocumentTypeSelector';
import { StyledSearchTextField } from '../../../components/AdvancedSearch/SearchSectionInput/StyledTextField';
import { Close, CopyAll, Delete, Download, SearchOutlined, Tag } from '@mui/icons-material';
import { useLazyEffect } from '../../../hooks/useLazyEffect';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../theme';
import { isEmpty } from 'lodash';
import DeleteDocumentDialog from '../../../components/Documents/Tables/Documents/DeleteDocumentDialog';
import { ClauseData, EntityType, IDocument } from '../../../@types/Document';

const DocumentsPage = () => {
    const [selectedType, setSelectedType] = useState<string>('All');
    const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [displayedDocuments, setDisplayedDocuments] = useState<IDocument[]>([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const { t } = useTranslation();

    const params = {
        page: page + 1,
        size: rowsPerPage,
        sort_by: '_upload_timestamp',
        sort_order: -1,
        name_filter: searchText,
        type_filter: selectedType === 'All' ? '' : selectedType,
    };

    const handleUploadSuccess = (res) => {
        toast.success('Successfully uploaded document');
        let newDocs: IDocument[]=[]
        res.entries.forEach((doc) => {
            newDocs.push({
                id: doc.sha1,
                name: doc.name,
                status: doc.status,
                type: doc.type,
                clauses: {} as ClauseData,
                entities: {} as EntityType,
                box_details: doc.box_details,
            })
        });
        setDisplayedDocuments(displayedDocuments.concat(newDocs));
    };

    const handleUploadError = (error) => {
        if (error.message) {
            toast.error(error.message);
            return;
        }
        toast.error(error.response.data.message);
    };

    const copyLinksToClipboard = () => {
        const links = selectedDocuments.map((document) => {
            return `${window.location.origin}/client/insights/${document.id}`;
        });
        navigator.clipboard.writeText(links.join('\n'));
        toast.success('Document links have been copied to clipboard.');
    };

    const {
        isLoading,
        isFetching,
        error,
        documents,
        refetch,
    } = useDocuments(params, 'documents');

    useEffect(() => {
        setDisplayedDocuments(documents);
    }, [documents]);

    const removeDocuments = (docs: IDocument[]) => {
        setDisplayedDocuments(
            displayedDocuments.filter(
                (document) => !docs.map((doc) => doc.id).includes(document.id)
            )
        );
        setSelectedDocuments([]);
    };

    const showLoading = isLoading || isFetching;

    useLazyEffect(() => {
        refetch();
    }, [selectedType, searchText]);

    return (
        <>
            <Flex alignCenter justifyBetween>
                <Container width="616px">
                    <StyledSearchTextField
                        fullWidth
                        size="small"
                        autoComplete="off"
                        sx={{ background: 'rgba(0, 0, 0, 0.06)' }}
                        placeholder="Search your contracts"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {searchText && (
                                        <Container
                                            topOuterSpacing={0.4}
                                            cursor="pointer"
                                            onClick={() => setSearchText('')}
                                        >
                                            <Close fontSize="small" />
                                        </Container>
                                    )}
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Container>
                <BoxUploadDocumentButton
                    onUploadSuccess={handleUploadSuccess}
                    onUploadError={handleUploadError}
                />
            </Flex>
            <Container topOuterSpacing={1.3}>
                <CardContainer>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Flex justifyBetween>
                            <Tabs value={0} style={{ overflow: 'visible' }}>
                                <Tab
                                    label={
                                        <Flex alignCenter>
                                            <span>{t('core.text.documents')}</span>
                                            {!showLoading && (
                                                <Container leftOuterSpacing={1}>
                                                    <Badge
                                                        badgeContent={displayedDocuments.length}
                                                        color="primary"
                                                    />
                                                </Container>
                                            )}
                                        </Flex>
                                    }
                                />
                            </Tabs>
                            <Flex>
                                {!isEmpty(selectedDocuments) && !showLoading && (
                                    <Flex
                                        style={{
                                            backgroundColor: 'whitesmoke',
                                            borderRadius: '10px',
                                            gap: '10px',
                                            marginRight: '15px',
                                            marginBottom: '10px',
                                            paddingRight: '5px',
                                            paddingLeft: '5px',
                                        }}
                                        alignCenter
                                    >
                                        <Chip
                                            label={selectedDocuments.length}
                                            style={{
                                                backgroundColor: '#DCDCDC',
                                                color: Theme.primary,
                                                fontWeight: 'bold',
                                            }}
                                        />
                                        {/* TODO: Implement add tag when endpoint is available */}
                                        <IconButton
                                            sx={{ '&:hover': { color: Theme.primary } }}
                                            disabled={true}
                                        >
                                            <Tooltip id="button-tag" title="Add tag">
                                                <Tag />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton
                                            sx={{ '&:hover': { color: Theme.primary } }}
                                            onClick={() => copyLinksToClipboard()}
                                        >
                                            <Tooltip id="button-copy" title="Copy shareable links">
                                                <CopyAll />
                                            </Tooltip>
                                        </IconButton>
                                        {/* TODO: Implement multi-download when endpoint is available */}
                                        <IconButton
                                            sx={{ '&:hover': { color: Theme.primary } }}
                                            disabled={true}
                                        >
                                            <Tooltip id="button-download" title="Download">
                                                <Download />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton
                                            sx={{ '&:hover': { color: Theme.primary } }}
                                            onClick={() => setShowDeleteDialog(true)}
                                        >
                                            <Tooltip id="button-delete" title="Delete">
                                                <Delete />
                                            </Tooltip>
                                        </IconButton>
                                    </Flex>
                                )}
                                <DocumentsTypeSelector
                                    selectedType={selectedType}
                                    setSelectedType={setSelectedType}
                                    isATableFilter
                                />
                            </Flex>
                        </Flex>
                    </Box>
                    <DeleteDocumentDialog
                        open={showDeleteDialog}
                        documents={selectedDocuments}
                        onClose={() => {
                            setShowDeleteDialog(false);
                        }}
                        onDeleteSuccess={() => {
                            setShowDeleteDialog(false);
                            removeDocuments(selectedDocuments);
                        }}
                    />
                    `
                    <TabPanel value={0} index={0}>
                        <>
                            {showLoading && (
                                <Loading message={t('documents.page.placeholder.loading')} />
                            )}
                            {error && <ErrorPlaceholder message={t('core.text.error')} />}
                            {!showLoading && Boolean(displayedDocuments.length) && (
                                <DocumentsTable
                                    documents={displayedDocuments}
                                    refetch={refetch}
                                    removeDocuments={removeDocuments}
                                    setSelectedDocuments={setSelectedDocuments}
                                    selectedDocuments={selectedDocuments}
                                />
                            )}
                            {!showLoading && !Boolean(displayedDocuments.length) && (
                                <Empty message={t('documents.page.placeholder.empty')} />
                            )}
                        </>
                    </TabPanel>
                </CardContainer>
            </Container>
        </>
    );
};

export default DocumentsPage;
