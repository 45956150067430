import CardContainer from '../../../components/UI/Common/Card/Card';
import Container from '../../../components/UI/Common/Container';
import Flex from 'styled-flex-component';
import { Box, Link, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useSavedQueries } from '../../../hooks/useSavedQueries';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import Loading from '../../../components/UI/Common/Loading';
import ErrorPlaceholder from '../../../components/UI/Common/ErrorPlaceholder';
import SavedQueriesTable from '../../../components/SavedQueries/Table';
import Empty from '../../../components/UI/Common/Empty';
import { Text } from '../../../components/UI/Typography';
import { useTranslation } from 'react-i18next';

const QueriesPage = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const params = {
        page: page + 1,
        size: rowsPerPage,
    };

    const { isLoading, isFetching, error, savedQueries, refetch } = useSavedQueries(
        params,
        'saved-queries'
    );

    const showLoading = isLoading || isFetching;

    return (
        <Container>
            <CardContainer>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Flex justifyBetween>
                        <Tabs value={0} style={{ overflow: 'visible' }}>
                            <Tab label={t('queries.table.title')} />
                        </Tabs>
                    </Flex>
                </Box>
                <TabPanel value={0} index={0}>
                    <>
                        {showLoading && <Loading message={t('queries.page.placeholder.loading')} />}
                        {error && <ErrorPlaceholder message={t('core.text.error')} />}
                        {!showLoading && Boolean(savedQueries.length) && (
                            <SavedQueriesTable savedQueries={savedQueries} refetch={refetch} />
                        )}
                        {!showLoading && !Boolean(savedQueries.length) && (
                            <Empty
                                message={
                                    <Text>
                                        {t('queries.page.placeholder.empty')}{' '}
                                        <Link href="/client/advanced-search">
                                            {t('sidebar.text.item.advancedSearch')}
                                        </Link>{' '}
                                        {t('core.text.page')}
                                    </Text>
                                }
                            />
                        )}
                    </>
                </TabPanel>
            </CardContainer>
        </Container>
    );
};

export default QueriesPage;
