import { Chip } from '@mui/material';
import { truncate } from 'lodash';
import Container from '../Common/Container';

interface Props {
    label: string;
}

export default function DocumentTypeChip(props: Props) {
    return (
        <Container maxWidth={14.1875}>
            <Chip label={truncate(props.label)} />
        </Container>
    );
}
