import { useEffect, useState } from 'react';
import { fetchBEVersion } from '../../../api/config.api';
import { LINT_VERSION } from '../../../constants';
import Container from '../Common/Container';

export default function VersionNumber() {
    const [backendVersion, setBackendVersion] = useState<string>('');
    const getConfig = async () => {
        try {
            const response = await fetchBEVersion();
            setBackendVersion(response);
        } catch (e) {}
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <Container
            style={{
                position: 'absolute',
                bottom: 0,
                color: 'rgba(255,255,255, 0.5)',
                padding: '1em',
                fontSize: '0.8rem',
            }}
        >
            FE: {LINT_VERSION} / BE: {backendVersion}
        </Container>
    );
}
