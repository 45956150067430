import { Button, IconButton, TableCell, TableRow } from '@mui/material';
import Container from '../../UI/Common/Container';
import Flex from 'styled-flex-component';
import { Text } from '../../UI/Typography';
import { DeleteOutline } from '@mui/icons-material';
import { ComparisonStatus } from '../../../enums/ComparisonStatus';
import { useNavigate } from 'react-router-dom';
import DeleteComparisonDialog from './DeleteComparisonDialog';
import { useState } from 'react';
import ErrorInfoDropdown from './ErrorInfoDropdown';
import { IComparison } from '../../../@types/Comparison';
import { useTranslation } from 'react-i18next';
import { getUserSessionData } from '../../../utils/userSession';
import ComparisonProcessingProgress from '../../ComparisonProcessingProgress/ComparisonProcessingProgress';

interface Props {
    comparison: IComparison;
    onDeleteSuccess: () => void;
    onReUploadSuccess: () => void;
}

export default function ComparisonsTableItem(props: Props) {
    const [comparison, setComparison] = useState<IComparison>(props.comparison);
    const navigate = useNavigate();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { t } = useTranslation();
    const user = getUserSessionData();

    return (
        <>
            <TableRow
                key={comparison.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                {/* Document Link */}
                <TableCell>
                    <Container wordBreak>{comparison.name}</Container>
                </TableCell>
                {/* Description */}
                <TableCell>
                    <Container wordBreak>{comparison.description}</Container>
                </TableCell>
                {/* Status */}
                <TableCell>
                    <Flex alignCenter>
                        {comparison.status === ComparisonStatus.PROCESSING && (
                            <ComparisonProcessingProgress
                                comparisonId={comparison.id}
                                tenantId={user?.tenantId as string}
                                onComparisonReady={() => {
                                    setComparison((c) => ({
                                        ...c,
                                        status: ComparisonStatus.READY,
                                    }));
                                }}
                            />
                        )}

                        {comparison.status === ComparisonStatus.READY && (
                            <Container>
                                <Text customFontSize={0.875} capitalize>
                                    {comparison.status}
                                </Text>
                            </Container>
                        )}

                        {comparison.status === ComparisonStatus.ERROR && (
                            <Container leftOuterSpacing={0.8}>
                                <ErrorInfoDropdown
                                    comparison={comparison}
                                    onReUploadSuccess={props?.onReUploadSuccess}
                                />
                            </Container>
                        )}
                    </Flex>
                </TableCell>
                {/* View Comparisons */}
                <TableCell>
                    <Button
                        variant="outlined"
                        disabled={comparison.status === ComparisonStatus.PROCESSING}
                        onClick={() => navigate(`/client/comparison/${comparison.id}`)}
                    >
                        <Text>{t('comparisons.page.action.viewComparisons')}</Text>
                    </Button>
                </TableCell>
                {/* Delete */}
                <TableCell>
                    <IconButton
                        color="inherit"
                        disabled={comparison.status === ComparisonStatus.PROCESSING}
                        onClick={() => setShowDeleteDialog(true)}
                    >
                        <DeleteOutline />
                    </IconButton>
                </TableCell>
            </TableRow>
            <DeleteComparisonDialog
                open={showDeleteDialog}
                comparison={props.comparison}
                onClose={() => {
                    setShowDeleteDialog(false);
                }}
                onDeleteSuccess={() => {
                    setShowDeleteDialog(false);
                    props?.onDeleteSuccess();
                }}
            />
        </>
    );
}
