import { CloseOutlined } from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';
import { truncate } from 'lodash';
import Container from '../../UI/Common/Container';
import { IAdvancedSearchPageSearchFilter } from '../../../pages/Client/AdvancedSearch/Page';
import { Theme } from '../../../theme';

interface IFilterChipProps {
    clause: IAdvancedSearchPageSearchFilter;
    index: number;
    onFilterRemove?: (index: number) => void;
    setIsFiltersOverlayOpened?: (val: boolean) => void;
    setSelectedClauseIndex?: (index: number | null) => void;
}

export default function SearchSectionFilterChip({
    clause,
    index,
    onFilterRemove,
    setIsFiltersOverlayOpened,
    setSelectedClauseIndex,
}: IFilterChipProps) {
    const onDelete = () => {
        if (onFilterRemove) {
            onFilterRemove(index);
        }
    };
    const label = `${truncate(clause.filterLabel)} ${
        clause.value ? `<b>${clause.display}</b>` : ''
    }`;

    const canBeDeleted = onFilterRemove && setIsFiltersOverlayOpened && setSelectedClauseIndex;

    return (
        <Container maxWidth={24}>
            <Tooltip title={<p dangerouslySetInnerHTML={{ __html: label }} />}>
                <>
                    {canBeDeleted && (
                        <Chip
                            label={<p dangerouslySetInnerHTML={{ __html: label }} />}
                            onDelete={() => onDelete()}
                            onClick={() => {
                                setIsFiltersOverlayOpened(true);
                                setSelectedClauseIndex(index);
                            }}
                            style={{
                                backgroundColor: clause.value
                                    ? 'rgba(3, 138, 255, 0.12)'
                                    : 'rgba(0, 0, 0, 0.08)',
                                color: Theme.primaryDark,
                            }}
                            deleteIcon={<CloseOutlined />}
                        />
                    )}
                    {!canBeDeleted && (
                        <Chip
                            label={<p dangerouslySetInnerHTML={{ __html: label }} />}
                            style={{
                                backgroundColor: clause.value
                                    ? 'rgba(3, 138, 255, 0.12)'
                                    : 'rgba(0, 0, 0, 0.08)',
                                color: 'rgba(13, 24, 50, 0.87)',
                            }}
                        />
                    )}
                </>
            </Tooltip>
        </Container>
    );
}
