// For intial implementation only: store insights template in session

export function storeTemplate(template) {
    let templateList = JSON.parse(sessionStorage.getItem('template_list') ?? '[]');
    const index =
        templateList.findIndex((item) => item.documentName == template.documentName) ?? [];
    if (index != -1) updateTemplate(index, template);
    else addTemplate(template);
}

export function addTemplate(template) {
    sessionStorage.setItem('template_list', JSON.stringify(getTemplateList().concat(template)));
}

export function updateTemplate(index, template) {
    let templateList = getTemplateList();
    templateList[index] = template;
    sessionStorage.setItem('template_list', JSON.stringify(templateList));
}

export function getTemplate(documentName) {
    const templateList = JSON.parse(sessionStorage.getItem('template_list') ?? '[]');
    return (
        templateList.find((template) => template.documentName == documentName) ?? {
            documentName,
            list: [],
        }
    );
}

export function getTemplateList() {
    return JSON.parse(sessionStorage.getItem('template_list') ?? '[]');
}

export function removeTemplateList() {
    sessionStorage.removeItem('template_list');
}
