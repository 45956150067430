import { Info } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { reProcessDocument } from '../../../../api/box/files';
import { IDocument } from '../../../../@types/Document';
import BoxUploadDocumentButton from '../../../UI/BoxUploadDocumentButton';
import { Theme } from '../../../../theme';
import { fullyRemoveDocument } from '../../../../api/files.api';
import { toast } from 'react-toastify';

interface Props {
    document: IDocument;
    onReUploadSuccess: () => void;
}

export default function ErrorInfoDropdown({ document, onReUploadSuccess }: Props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const reProcessDoc = async () => {
        try {
            setIsLoading(true);
            await reProcessDocument(document.box_details?.file_id as string);
            toast.success('Successfully re-processed document');
        } catch (e) {
            toast.error('Something went wrong. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleBeforeUpload = () => {
        setIsLoading(true);
        return fullyRemoveDocument(document.id, document.box_details?.file_id as string);
    };

    return (
        <>
            {isLoading ? (
                <CircularProgress size={20} color="inherit" />
            ) : (
                <Info
                    onClick={handleClick}
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    style={{ cursor: 'pointer' }}
                />
            )}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
            >
                <MenuItem onClick={reProcessDoc}>
                    <Typography color="error">re-process</Typography>
                </MenuItem>

                <MenuItem onClick={() => setIsLoading(true)}>
                    <BoxUploadDocumentButton
                        removeStartIcon={true}
                        variant="text"
                        color={Theme.error}
                        label="re-upload"
                        beforeUpload={handleBeforeUpload}
                        onUploadSuccess={() => {
                            onReUploadSuccess();
                            setIsLoading(false);
                        }}
                        onUploadError={() => {
                            toast.error('Something went wrong. Please try again.');
                            setIsLoading(false);
                        }}
                    />
                </MenuItem>
            </Menu>
        </>
    );
}
