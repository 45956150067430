import CardContainer from '../../../components/UI/Common/Card/Card';
import Container from '../../../components/UI/Common/Container';
import Flex from 'styled-flex-component';
import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import Loading from '../../../components/UI/Common/Loading';
import ErrorPlaceholder from '../../../components/UI/Common/ErrorPlaceholder';
import { Text } from '../../../components/UI/Typography';
import { useClauseLibrary } from '../../../hooks/useClauseLibrary';
import ClauseLibraryTable from '../../../components/ClauseLibrary/Table';
import { Button, Grid } from '@mui/material';
import { Theme } from '../../../theme';
import { useTranslation } from 'react-i18next';

const ClauseLibrary = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const params = {
        page: page + 1,
        size: rowsPerPage,
    };

    const { isLoading, isFetching, error, clauseLibrary, refetch } = useClauseLibrary();

    const showLoading = isLoading || isFetching;

    return (
        <Container>
            <Grid container justifyContent="flex-end" sx={{ marginBottom: 2 }}>
                <Button variant="contained" disabled={true}>
                    <Text uppercase color={Theme.whiteColor} customletterSpacing={0.05}>
                        {t('clauseLibrary.action.addClause')}
                    </Text>
                </Button>
            </Grid>
            <CardContainer>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Flex justifyBetween>
                        <Tabs value={0} style={{ overflow: 'visible' }}>
                            <Tab label={t('clauseLibrary.table.title')} />
                        </Tabs>
                    </Flex>
                </Box>
                <TabPanel value={0} index={0}>
                    <>
                        {showLoading && <Loading message={t('clauses.page.placeholder.loading')} />}
                        {error && <ErrorPlaceholder message={t('core.text.error')} />}
                        {!showLoading && Boolean(clauseLibrary.length) && (
                            <ClauseLibraryTable clauseLibrary={clauseLibrary} refetch={refetch} />
                        )}
                    </>
                </TabPanel>
            </CardContainer>
        </Container>
    );
};

export default ClauseLibrary;
